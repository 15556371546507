import { useEffect, useState, Fragment, useContext } from "react";
import "./style.css";
import family from "../../Assets/familyinsurance.png";
import clipboard from "clipboard-copy";
import FooterComponent from "../../Components/Footer/index";
import axios from "axios";
import { planData } from "../../Components/ContextApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const profilebtns = [
  { id: 1, buttontype: "profile", btnName: "Profile" },
  { id: 2, buttontype: "myproducts", btnName: "MyProducts" },
  { id: 3, buttontype: "bonuses", btnName: "Bonuses" },
  { id: 4, buttontype: "referrals", btnName: "Referrals" },
];

const UserProfile = () => {
  const { userId, setCredentials } = useContext(planData);
  const navigateto = useNavigate();
  const [activeButton, setActiveButton] = useState("profile");
  const [profileDetails, setProfileDetails] = useState();
  const [tableName, setTableName] = useState("customer");
  const [propertyName, setPropertyName] = useState("customer");
  const [userProducts, setuserProducts] = useState([]);
  useEffect(() => {
    const currentURL = window.location.href;
    const username = currentURL.substring(currentURL.lastIndexOf("-") + 1);
    const userDetails = {
      tbname: tableName,
      propname: propertyName,
      // id: userId,
      id: userId ? userId : username,
    };
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://api.indiainsurancedeals.com/v1/common/read.php",
          userDetails
        );
        if (response) {
          if (tableName === "cust_policies") {
            setuserProducts(response.data.data);
          } else {
            setProfileDetails(response.data.data[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [tableName, userId, propertyName]);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    switch (buttonName) {
      case "profile":
        setTableName("customer");
        setPropertyName("customer");

        break;
      case "myproducts":
        setTableName("cust_policies");
        setPropertyName("cust_policies");

        break;
      case "referrals":
        setProfileDetails({
          referralLink: generateReferralLink(),
        });
        setTableName("customer");
        setPropertyName("customer");

        break;
      case "bonuses":
        setTableName("cust_bonus");
        setPropertyName("cust_bonus");

        break;
      default:
        setProfileDetails({
          name: "John Doe",
          mobile_number: "123-456-7890",
          email_id: "john.doe@example.com",
          address_line_1: "123 Main St, Cityville, Country",
        });
    }
  };
  const generateReferralLink = () => {
    return `https://dev.indiainsurancedeals.com/?ref=id:${userId}`;
  };

  const handleShareButton = (shareType) => {
    const referralLink = generateReferralLink();
    let shareUrl = "";
    switch (shareType) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `India Insurance Deals Best place to find the Value deals: ${referralLink}`
        )}`;
        break;
      case "email":
        const subject = encodeURIComponent("Check out India Insurance Deals");
        const body = encodeURIComponent(
          `India Insurance Deals Best place to find the Value deals:\n${referralLink}`
        );
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          referralLink
        )}`;
        break;
      case "twitter":
        const shareText = `India Insurance Deals Best place to find the Value deals: ${referralLink}`;
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          shareText
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          referralLink
        )}`;
        break;
      default:
        break;
    }
    if (shareUrl) window.open(shareUrl);
  };

  const copyToClipboard = (text) => {
    clipboard(text);
  };

  const handleButtonCopy = () => {
    const referralLink = generateReferralLink();

    copyToClipboard(referralLink);
    alert("Link copied to clipboard!");
  };

  const LogoutHandler = () => {
    Cookies.remove("userData");
    setCredentials(true);
    alert("Logged out Successfully");
    navigateto("/");
  };
  return (
    <>
      <div className="profile-main-container">
        <div className="profile-button-container">
          {profilebtns.map((btn, index) => {
            return (
              <button
                key={index}
                className={`p-product-btn ${
                  btn.buttontype === activeButton ? "active" : ""
                }`}
                onClick={() => handleButtonClick(btn.buttontype)}
              >
                {btn.btnName}
              </button>
            );
          })}
        </div>
        <div className="details-container">
          <div className="profile-product-information">
            <div className="information">
              <h4>INDIA INSURANCE DEALS</h4>
              <span>
                Insurance is the shield that turns uncertainty into confidence,
                guarding your life, health, and assets against the unpredictable
                winds of fate
              </span>
            </div>
            <div className="profile-info-image">
              <img src={family} alt="" />
            </div>
          </div>
          <div className="p-myproducts-card-container">
            {activeButton === "myproducts" && (
              <>
                {userProducts.map((p, index) => {
                  return (
                    <Fragment key={index + 1}>
                      <div className="card1">
                        <div className="card-image1"></div>
                        <div className="category1">
                          <strong> Policy Name </strong>:{p.policy_name}{" "}
                        </div>
                        <div className="heading1">
                          <p>
                            <strong>Premium Amount</strong> :{p.premium_amount}
                          </p>
                          <p>
                            <strong> Premium Start Date</strong>:
                            {p.premium_start_date}
                          </p>
                          <p>
                            <strong>Comission Amount</strong> :
                            {p.comission_amount}
                          </p>
                          <p>
                            <strong>Comission Received</strong> :
                            {p.comission_received}
                          </p>
                          <p>
                            <strong>Policy Term</strong> :{p.policy_term}
                          </p>
                          <div className="author1"></div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </>
            )}
            {activeButton === "referrals" && (
              // <div className="referral-container">
              <div className="referral-container">
                <p className="bonus-highlighting">
                  Refer A Friend and get Referral Bonus of ₹100
                </p>
                <div>
                  <button
                    onClick={() => {
                      handleShareButton("whatsapp");
                    }}
                    className="whatsapp-referral"
                  >
                    Share Via WhatsApp {"  "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.5em"
                      viewBox="0 0 448 512"
                      fill="white"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      handleShareButton("email");
                    }}
                    className="email-referral"
                  >
                    Share via Email {"  "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.2em"
                      viewBox="0 0 512 512"
                      fill="white"
                    >
                      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                    </svg>
                  </button>
                  <button
                    className="facebook-referral"
                    onClick={() => {
                      handleShareButton("facebook");
                    }}
                  >
                    share via facebook {"   "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.2em"
                      viewBox="0 0 320 512"
                      fill="white"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg>
                  </button>
                  <button
                    className="twitter-referral"
                    onClick={() => {
                      handleShareButton("twitter");
                    }}
                  >
                    share via twitter {"   "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.2em"
                      viewBox="0 0 512 512"
                      fill="white"
                    >
                      <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                    </svg>
                  </button>
                  <button
                    className="telegram-referral"
                    onClick={() => {
                      handleShareButton("telegram");
                    }}
                  >
                    share via telegram {"   "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.5em"
                      viewBox="0 0 496 512"
                      fill="#0190F0"
                      id="telegram-icon"
                    >
                      <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z" />
                    </svg>
                  </button>
                  <button onClick={handleButtonCopy} className="copy-referral">
                    Copy Referral link {"   "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.3em"
                      viewBox="0 0 448 512"
                      fill="white"
                    >
                      <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
                    </svg>
                  </button>
                </div>
              </div>
              // </div>
            )}
            {activeButton === "bonuses" && (
              <>
                <div className="card2">
                  <div className="card-image2">
                    <p className="bonus-highlighting2">
                      {" "}
                      Referring A Friend can get you more bonus
                    </p>
                  </div>
                  <div className="category2"> Your Bonuses </div>
                  <div className="heading2">
                    <p>
                      Welcome Bonus:{" "}
                      <span className="bonus-amount">
                        ₹{profileDetails ? profileDetails.bonus_type : "3000"}
                      </span>
                    </p>
                    <p>
                      Bonus Amount:
                      <span className="bonus-amount">
                        ₹{profileDetails ? profileDetails.bonus_amount : "1000"}
                      </span>
                    </p>
                    <p>
                      Cashback Bonus:
                      <span className="bonus-amount">
                        ₹
                        {profileDetails ? profileDetails.cashbackBonus : "2000"}
                      </span>
                    </p>
                    <p>
                      Total Bonus: <span className="bonus-amount">₹8000</span>
                    </p>
                    <p>
                      Want to refer a friend ?{" "}
                      <span
                        className="refer-click"
                        onClick={() => setActiveButton("referrals")}
                      >
                        click here
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}
            {activeButton !== "myproducts" &&
              activeButton !== "referrals" &&
              activeButton !== "bonuses" && (
                <div className="profile-container">
                  <div>
                    <span>Name :</span>
                    <p>
                      {profileDetails
                        ? profileDetails.first_name +
                          " " +
                          profileDetails.middle_name
                        : "John"}
                    </p>
                  </div>
                  <div>
                    <span>Phone Number :</span>
                    <p>
                      {" "}
                      {profileDetails
                        ? profileDetails.mobile_number
                        : "+91 987877767655"}
                    </p>
                  </div>
                  <div>
                    <span>Email :</span>
                    <p>
                      {" "}
                      {profileDetails
                        ? profileDetails.email_id
                        : "examplemail@gmail.com"}
                    </p>
                  </div>
                  <div>
                    <span>Address :</span>
                    <p>
                      {" "}
                      {profileDetails
                        ? profileDetails.address_line_1
                        : "Haraluru"}
                      ,
                      {profileDetails
                        ? profileDetails.address_line_2
                        : "HSR Layout"}
                    </p>
                  </div>
                  <div>
                    <span>State :</span>
                    <p>
                      {" "}
                      {profileDetails ? profileDetails.state : "Karnataka"}
                    </p>
                  </div>
                  <div>
                    <span>City :</span>
                    <p> {profileDetails ? profileDetails.city : "Bengaluru"}</p>
                  </div>
                  <div>
                    <span>Country :</span>
                    <p> {profileDetails ? profileDetails.country : "India"}</p>
                  </div>
                  <div>
                    <span>Pincode :</span>
                    <p>
                      {" "}
                      {profileDetails ? profileDetails.pin_code : "517068"}
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="logout-container">
          <button className="logout-btn" onClick={LogoutHandler}>
            Log out
          </button>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default UserProfile;
