import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import emailjs from "emailjs-com";
import "./style.css";

function CustomModal({ productname }) {
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({
    product_Name: productname,
    name: "",
    phoneNumber: "",
    email: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });
  const handleClose = () => {
    setShow(false);
    setValidationErrors({
      name: "",
      phoneNumber: "",
      email: "",
    });
  };

  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const submitHandler = async () => {
    // Validate inputs
    const errors = {};
    if (!userData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!userData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    }
    if (!userData.email.trim()) {
      errors.email = "Email is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Construct the email parameters
    const emailParams = {
      to_email: "recipient@example.com", // Replace with the recipient's email address
      from_name: "Customer", // Replace with your name
      subject: "New General Product Inquiry",
      message: `Product: ${userData.product_Name}\n\n${JSON.stringify(
        userData,
        null,
        2
      )}`,
    };
    try {
      await emailjs.send(
        "service_io1643p",
        "template_ocpv7ly",
        emailParams,
        "_uBEmCU31tn-upplB"
      );
      alert(
        "Thank you for Submitting your Response. We will get back to you within 24 hours."
      );
    } catch (error) {
      console.error("Error sending email:", error);
    }
    handleClose();
  };

  return (
    <>
      <button className="modal-btn" onClick={handleShow}>
        Buy Now
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Enter your details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="userinput-container">
            <div className="form ProductName">
              <label>Product Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                name="name"
                value={productname}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form Name">
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                name="name"
                value={userData.name}
                required
                onChange={handleInputChange}
                className="userinput-modal"
              />
              <span className="error-message">{validationErrors.name}</span>
            </div>
            <div className="form PhoneNumber">
              <label>Phone Number</label>
              <input
                className="userinput-modal"
                type="number"
                max={10}
                placeholder="Enter your phone number"
                name="phoneNumber"
                value={userData.phoneNumber}
                required
                onChange={handleInputChange}
              />
              <span className="error-message">
                {validationErrors.phoneNumber}
              </span>
            </div>
            <div className="form Email">
              <label>Email</label>
              <input
                className="userinput-modal"
                type="email"
                placeholder="Enter your email"
                name="email"
                value={userData.email}
                required
                onChange={handleInputChange}
              />
              <span className="error-message">{validationErrors.email}</span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-close" onClick={handleClose}>
            Close
          </button>
          <button className="modal-submit" onClick={submitHandler}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CustomModal;
