import { Fragment } from "react";
import { useContext } from "react";
import { planData } from "../ContextApi";
import { useNavigate } from "react-router-dom";
import "./style.css";
const CustomDropdown = ({ insuranceplans, plantype }) => {
  const { getPathData } = useContext(planData);

  const navigateto = useNavigate();

  const navigatetoproducts = (plan) => {
    getPathData(plan.id);
    if (plan.type === "life") {
      navigateto(`/products-${plan.id}`);
    } else if (plan.type === "health") {
      navigateto(`/healthproducts-${plan.id}`);
    } else {
      navigateto(`/generalproducts-${plan.id}`);
    }
  };
  return (
    <>
      <div className="dropdown-container">
        <h6 className="life-plans">{plantype} </h6>
        <div className="plan-options">
          {insuranceplans.map((p, i) => {
            return (
              <Fragment key={i}>
                <p
                  className="allproducts-link"
                  onClick={() => navigatetoproducts(p)}
                >
                  {p.plans}
                </p>
              </Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustomDropdown;
