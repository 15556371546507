import { Fragment, useState, memo, useEffect } from "react";
import { useContext } from "react";
import { planData } from "../ContextApi";
import FooterComponent from "../Footer/index";
import "./style.css";
import PdfDownloads from "../PdfDownloads";
import { useParams } from "react-router-dom";
import axios from "axios";

const buttons = [
  {
    id: "description",
    title: "Description",
  },
  {
    id: "benefits",
    title: "Benefits",
  },
  {
    id: "highlights",
    title: "Highlights",
  },
  {
    id: "faq",
    title: "FAQ",
  },
];

const Deta = () => {
  const [product, setProduct] = useState({});
  const [details, setDetails] = useState();
  const [activeButton, setActiveButton] = useState("description");

  const detailsHandler = (id) => {
    setActiveButton(id);
  };
  const { id } = useParams();

  useEffect(() => {
    const url =
      "https://api.indiainsurancedeals.com/v1/common/product_read_by_id.php";
    async function getproduct() {
      try {
        const response = await axios.post(url, JSON.stringify({ id: 2 }), {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response) {
          setProduct(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getproduct();
  }, []);

  return (
    <>
      <div className="">
        <div
          style={{
            minHeight: "500px",
          }}
        >
          <div className="product-buttons">
            {buttons.map((btn) => {
              return (
                <Fragment key={btn.id}>
                  <button
                    className={`product-btn ${
                      btn.id === activeButton ? "active" : ""
                    }`}
                    onClick={() => detailsHandler(btn.id)}
                  >
                    {btn.title}
                  </button>
                </Fragment>
              );
            })}
          </div>
          <div className="details-of-product">
            <h4 className="product-name">{product?.product?.product_name}</h4>
            {/* <img src={companyLogo} alt="" /> */}
          </div>
          <div className="product-info-container">
            {activeButton === "benefits" ? (
              <ul className="ul.my-list">
                {product?.product_benefits?.map((benefit, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{benefit?.description}</li>
                    </Fragment>
                  );
                })}
              </ul>
            ) : activeButton === "highlights" ? (
              <>
                {product?.product_highlights?.map((h) => {
                  return (
                    <Fragment key={h.id}>
                      <li>{h.description}</li>
                    </Fragment>
                  );
                })}
              </>
            ) : activeButton === "faq" ? (
              <>
                <div>
                  {product?.product_faq?.map((faq) => {
                    return (
                      <>
                        <div className="my-2">
                          <p className="text-black fw-bold m-0">
                            {faq.faq_question}
                          </p>
                          <span className="text-15 ">{faq.faq_answer}</span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <p className="product-summary">
                {product?.product_description?.description}
              </p>
            )}
          </div>
          <div className="product-download">
            {/* <PdfDownloads id={product1?.id} /> */}
            <button className="text-white bg-primary px-4 border-0 rounded-2 fw-bold  p-2 ">
              Brochure
            </button>
          </div>
        </div>
        <div className="product-footer-container">
          <FooterComponent />
        </div>
      </div>
    </>
  );
};
export default Deta;
