import React from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-dropdown/style.css";
import ContextApi from "./Components/ContextApi";

const root = ReactDOM.createRoot(document.getElementById("root"));
Modal.setAppElement("#root");
root.render(
  <React.StrictMode>
    <ContextApi>
      <App />
    </ContextApi>
  </React.StrictMode>
  //  document.getElementById('root')
);

reportWebVitals();
