import CustomerSupport from "../../Components/CustomerSupport";
import Footer from "../../Components/Footer";
import Highlights from "../../Components/Highlights";
import InsuranceTypes from "../../Components/InsuranceTypes";
import Introduction from "../../Components/Introduction";
import MainHighlights from "../../Components/MainHighlights";
import NewsComponent from "../../Components/News";
import { memo } from "react";
import "./style.css";
const HomeComponent = () => {
  return (
    <>
      <div className="home-main-container">
        <div className="home-intro-container">
          <Introduction />
          <Highlights />
        </div>
        <div className="home-insurance-container">
          <InsuranceTypes />
        </div>
        <div className="home-highlights-container">
          <MainHighlights />
        </div>
        <div className="home-news-container">
          <NewsComponent />
        </div>
        <div className="home-customer-container">
          <CustomerSupport />
        </div>
        <div className="home-footer-container">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default memo(HomeComponent);
