//complete working code==============================
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Data from "../../Data/Allroductdetails.json";
// import Data1 from "../../Data/planDetails.json";
// import "./style.css";

// // const ProductTypeLink = "https://dev.indiainsurancedeals.com/products-";
// // const ProductLink = "https://dev.indiainsurancedeals.com/products";
// const ContactLink = "https://dev.indiainsurancedeals.com/contact";

// const truncateText = (text, maxLength) => {
//   if (text.length > maxLength) {
//     return text.substring(0, maxLength) + "...";
//   }
//   return text;
// };

// export const ChatBotResponse = ({ text, maxLength = 100, isBold = false }) => {
//   const [showMore, setShowMore] = useState(false);

//   const toggleShowMore = () => {
//     setShowMore(!showMore);
//   };

//   const truncatedText = truncateText(text, maxLength);

//   return (
//     <div>
//       {showMore ? (
//         <div className="chat-response-text">
//           {text}
//           <button onClick={toggleShowMore} className="show-more-button">
//             Show less
//           </button>
//         </div>
//       ) : (
//         <div className={isBold ? "bold-text" : ""}>
//           {truncatedText}
//           {text.length > maxLength && (
//             <button onClick={toggleShowMore} className="show-more-button">
//               Show more
//             </button>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };
// export const analyze = async (text, product) => {
//   if (text) {
//     text = text.toLowerCase(); // Convert to lowercase if text is defined
//   }

//   if (
//     text &&
//     (text.startsWith("hi") ||
//       text.startsWith("hai") ||
//       text.startsWith("hello") ||
//       text.startsWith("hey"))
//   ) {
//     return ["Hi, How can I help you?"];
//   } else if (
//     text &&
//     (text.includes("what are insurances plans") ||
//       text.includes("types of insurances plans") ||
//       text.includes("insurances plans"))
//   ) {
//     return [
//       "Term Insurance With Premium",
//       "Term Insurance With No Premium",
//       "Child Education Plan",
//       "Child Marriage Plan",
//       "Life Long Income Plan",
//       "Lumpsum Plan",
//       "Pension Plan",
//       "Money Back Plan",
//       "Monthly Regular Income Plan",
//     ].map((e, index) => ({
//       key: index,
//       message: e,
//     }));
//   } else {
//     const filteredData =
//       Data &&
//       Data.find((item) => {
//         return (
//           item.Product_Name && item.Product_Name.toLowerCase().includes(text)
//         );
//       });

//     if (filteredData) {
//       return [
//         { key: "Product_Name", message: `Product Name: ${filteredData.Product_Name || ""}` },
//         { key: "Description", message: `Description: ${filteredData.productdescription || ""}` },
//         { key: "Benefits", message: `Benefits: ${filteredData.Benefits || ""}` },
//         { key: "More Info", message: <a href={`https://dev.indiainsurancedeals.com/productdetails-${filteredData.id}`}>more info</a> },
//         { key: "Contact", message: <a href="https://dev.indiainsurancedeals.com/contact">contact us</a> },
//       ];
//     } else {
//       const planDetailsData =
//         Data1 &&
//         Data1.find((plan) => {
//           return (
//             plan.Product_Name && plan.Product_Name.toLowerCase().includes(text)
//           );
//         });

//       if (planDetailsData) {
//         return [
//           { key: "Plan Name", message: `Plan Name: ${planDetailsData.Product_Name || ""}` },
//           { key: "Plan Description", message: `Plan Description: ${planDetailsData.description || ""}` },
//           { key: "Benefits", message: `Benefits: ${planDetailsData.benefits || ""}` },
//           { key: "More Info", message: <a href={`https://dev.indiainsurancedeals.com/products-${planDetailsData.id}`}>more info</a> },
//           { key: "Contact", message: <a href="https://dev.indiainsurancedeals.com/contact">contact us</a> },
//         ];
//       } else {
//         // Fallback message when no specific response is found
//         return [
//           { key: "Fallback", message: "Sorry, I didn't find any information for that. Please rephrase your question or ask something else." },
//         ];
//       }
//     }
//   }
// };
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Data from "../../Data/Allroductdetails.json";
import Data1 from "../../Data/planDetails.json";
import "./style.css";

const ContactLink = "https://dev.indiainsurancedeals.com/contact";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const ChatBotResponse = ({ text, maxLength = 100, isBold = false }) => {
  const [showMore, setShowMore] = useState(false);
 const[hide,sethide]=useState(false)
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const truncatedText = truncateText(text, maxLength);

  const filteredData =
    Data &&
    Data.find((item) => {
      return (
        item.Product_Name &&
        item.Product_Name.toLowerCase().includes(text)
      );
    });

  const planDetailsData =
    Data1 &&
    Data1.find((plan) => {
      return (
        plan.Product_Name &&
        plan.Product_Name.toLowerCase().includes(text)
      );
    });

  return (
    <div>
      {filteredData ? (
        <div>
          <div
            className={isBold ? "bold-text" : ""}
            onClick={toggleShowMore}
            style={{ cursor: "pointer" }}
          >
            {truncatedText}
          </div>
          {showMore && (
            <div className="chat-response-text">
              <div>Product Name:{filteredData.Product_Name || ""}</div>
              <div>Description: {filteredData.productdescription || ""}</div>
              <div>Benefits: {filteredData.Benefits || ""}</div>
              <div>
                More Info:{" "}
                <Link to={`https://dev.indiainsurancedeals.com/productdetails-${filteredData.id}`}>
                  more info
                </Link>
              </div>
              <div>
                Contact: <Link to={ContactLink}>contact us</Link>
              </div>
              <button onClick={toggleShowMore} className="show-more-button">
                Show less
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          {planDetailsData ? (
            <div>
              <div
                className={isBold ? "bold-text" : ""}
                onClick={toggleShowMore}
                style={{ cursor: "pointer" }}
              >
                {truncatedText}
              </div>
              {showMore && (
                <div className="chat-response-text">
                  <div>Plan Name: {planDetailsData.Product_Name || ""}</div>
                  <div>Description: {planDetailsData.description || ""}</div>
                  <div>Benefits: {planDetailsData.benefits || ""}</div>
                  <div>
                    More Info:{" "}
                    <Link to={`https://dev.indiainsurancedeals.com/products-${planDetailsData.id}`}>
                      more info
                    </Link>
                  </div>
                  <div>
                    Contact: <Link to={ContactLink}>contact us</Link>
                  </div>
                  <button onClick={toggleShowMore} className="show-more-button">
                    Show less
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className={isBold ? "bold-text" : ""}>
              {truncatedText}
              {text.length > maxLength && (
                <button onClick={toggleShowMore} className="show-more-button">
                  Show more
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const analyze = async (text, product) => {
  if (text) {
    text = text.toLowerCase(); // Convert to lowercase if text is defined
  }

  if (
    text &&
    (text.startsWith("hi") ||
      text.startsWith("hai") ||
      text.startsWith("hello") ||
      text.startsWith("hey"))
  ) {
    return ["Hi, How can I help you?"];
  } else if (
    text &&
    (text.includes("what are insurances plans") ||
      text.includes("types of insurances plans") ||
      text.includes("insurances plans"))
  ) {
    return [
      "Term Insurance With Premium",
      "Term Insurance With No Premium",
      "Child Education Plan",
      "Child Marriage Plan",
      "Life Long Income Plan",
      "Lumpsum Plan",
      "Pension Plan",
      "Money Back Plan",
      "Monthly Regular Income Plan",
    ].map((e, index) => ({
      key: index,
      message: e,
    }));
  } else {
    const filteredData =
      Data &&
      Data.find((item) => {
        return (
          item.Product_Name && item.Product_Name.toLowerCase().includes(text)
        );
      });

    if (filteredData) {
      return [
        { key: "Product_Name", message: `Product Name: ${filteredData.Product_Name || ""}` },
        { key: "Description", message: `Description: ${filteredData.productdescription || ""}` },
        { key: "Benefits", message: `Benefits: ${filteredData.Benefits || ""}` },
        { key: "More Info", message: <Link to={`https://dev.indiainsurancedeals.com/productdetails-${filteredData.id}`}>more info</Link> },
        { key: "Contact", message: <Link to={ContactLink}>contact us</Link> },
      ];
    } else {
      const planDetailsData =
        Data1 &&
        Data1.find((plan) => {
          return (
            plan.Product_Name && plan.Product_Name.toLowerCase().includes(text)
          );
        });

      if (planDetailsData) {
        return [
          { key: "Plan Name", message: `Plan Name: ${planDetailsData.Product_Name || ""}` },
          { key: "Plan Description", message: `Plan Description: ${planDetailsData.description || ""}` },
          { key: "Benefits", message: `Benefits: ${planDetailsData.benefits || ""}` },
          { key: "More Info", message: <Link to={`https://dev.indiainsurancedeals.com/products-${planDetailsData.id}`}>more info</Link> },
          { key: "Contact", message: <Link to={ContactLink}>contact us</Link> },
        ];
      } else {
        // Fallback message when no specific response is found
        return [
          { key: "Fallback", message: "Sorry, I didn't find any information for that. Please rephrase your question or ask something else." },
        ];
      }
    }
  }
};






//final analyze working
// export const analyze = (text, product) => {
//   if (text) {
//     text = text.toLowerCase(); // Convert to lowercase if text is defined
//   }

//   if (
//     text &&
//     (text.startsWith("hi") ||
//       text.startsWith("hai") ||
//       text.startsWith("hello") ||
//       text.startsWith("hey"))
//   ) {
//     return ["Hi, How can I help you?"];
//   } else if (
//     text &&
//     (text.includes("what are insurances plans") ||
//       text.includes("types of insurances plans") ||
//       text.includes("insurances plans"))
//   ) {
//     return [
//       "Term Insurance With Premium",
//       "Term Insurance With No Premium",
//       "Child Education Plan",
//       "Child Marriage Plan",
//       "Life Long Income Plan",
//       "Lumpsum Plan",
//       "Pension Plan",
//       "Money Back Plan",
//       "Monthly Regular Income Plan",
//     ].map((e, index) => (
//       <ChatBotResponse key={index} text={e} maxLength={100} />
//     ));
//   } else {
//     const filteredData =
//       Data &&
//       Data.find((item) => {
//         return (
//           item.Product_Name && item.Product_Name.toLowerCase().includes(text)
//         );
//       });

//     if (filteredData) {
//       // ProductType Info
//       return (
//         <div className="product">
//           <ChatBotResponse
//             text={`Product Name: ${filteredData.Product_Name || ""}`}
//             isBold={true}
//           />
//           <ChatBotResponse
//             text={`Description: ${filteredData.productdescription || ""}`}
//             isBold={true}
//           />
//           <ChatBotResponse
//             text={`Benefits: ${filteredData.Benefits || ""}`}
//             isBold={true}
//           />
//           <p>
//             <Link
//               to={`https://dev.indiainsurancedeals.com/productdetails-${filteredData.id}`}
//             >
//               more info
//             </Link>
//           </p>
//           <p>
//             <Link to={ContactLink}>contact us</Link>
//           </p>
//         </div>
//       );
//     } else {
//       const planDetailsData =
//         Data1 &&
//         Data1.find((plan) => {
//           return (
//             plan.Product_Name && plan.Product_Name.toLowerCase().includes(text)
//           );
//         });

//       if (planDetailsData) {
//         // Products Info
//         return (
//           <div className="product">
//             <ChatBotResponse
//               text={`Plan Name: ${planDetailsData.Product_Name || ""}`}
//               isBold={true}
//             />
//             <ChatBotResponse
//               text={`Plan Description: ${planDetailsData.description || ""}`}
//               isBold={true}
//             />
//             <ChatBotResponse
//               text={`Benefits: ${planDetailsData.benefits || ""}`}
//               isBold={true}
//             />
//             <p>
//               <Link
//                 to={`https://dev.indiainsurancedeals.com/products-${planDetailsData.id}`}
//               >
//                 more info
//               </Link>
//             </p>
//             <p>
//               <Link to={ContactLink}>contact us</Link>
//             </p>
//           </div>
//         );
//       } else {
//         // Fallback message when no specific response is found
//         return [
//           "Sorry, I didn't find any information for that. Please rephrase your question or ask something else.",
//         ];
//       }
//     }
//   }
// };
// utils.js
//final running  code===============
// export const analyze = async (text, product) => {
//   if (text) {
//     text = text.toLowerCase(); // Convert to lowercase if text is defined
//   }

//   if (
//     text &&
//     (text.startsWith("hi") ||
//       text.startsWith("hai") ||
//       text.startsWith("hello") ||
//       text.startsWith("hey"))
//   ) {
//     return ["Hi, How can I help you?"];
//   } else if (
//     text &&
//     (text.includes("what are insurances plans") ||
//       text.includes("types of insurances plans") ||
//       text.includes("insurances plans"))
//   ) {
//     return [
//       "Term Insurance With Premium",
//       "Term Insurance With No Premium",
//       "Child Education Plan",
//       "Child Marriage Plan",
//       "Life Long Income Plan",
//       "Lumpsum Plan",
//       "Pension Plan",
//       "Money Back Plan",
//       "Monthly Regular Income Plan",
//     ].map((e, index) => ({
//       key: index,
//       message: e,
//     }));
//   } else {
//     const filteredData =
//       Data &&
//       Data.find((item) => {
//         return (
//           item.Product_Name && item.Product_Name.toLowerCase().includes(text)
//         );
//       });

//     if (filteredData) {
//       return [
//         { key: "Product_Name", message: `Product Name: ${filteredData.Product_Name || ""}` },
//         { key: "Description", message: `Description: ${filteredData.productdescription || ""}` },
//         { key: "Benefits", message: `Benefits: ${filteredData.Benefits || ""}` },
//         { key: "More Info", message: <a href={`https://dev.indiainsurancedeals.com/productdetails-${filteredData.id}`}>more info</a> },
//         { key: "Contact", message: <a href="https://dev.indiainsurancedeals.com/contact">contact us</a> },
//       ];
//     } else {
//       const planDetailsData =
//         Data1 &&
//         Data1.find((plan) => {
//           return (
//             plan.Product_Name && plan.Product_Name.toLowerCase().includes(text)
//           );
//         });

//       if (planDetailsData) {
//         return [
//           { key: "Plan Name", message: `Plan Name: ${planDetailsData.Product_Name || ""}` },
//           { key: "Plan Description", message: `Plan Description: ${planDetailsData.description || ""}` },
//           { key: "Benefits", message: `Benefits: ${planDetailsData.benefits || ""}` },
//           { key: "More Info", message: <a href={`https://dev.indiainsurancedeals.com/products-${planDetailsData.id}`}>more info</a> },
//           { key: "Contact", message: <a href="https://dev.indiainsurancedeals.com/contact">contact us</a> },
//         ];
//       } else {
//         // Fallback message when no specific response is found
//         return [
//           { key: "Fallback", message: "Sorry, I didn't find any information for that. Please rephrase your question or ask something else." },
//         ];
//       }
//     }
//   }
// };










// import React, { useState } from "react";
// import Data from "../../Data/Allroductdetails.json";
// import Data1 from "../../Data/planDetails.json";
// import { Link } from "react-router-dom";
// import "./style.css";

// const ProductTypeLink = "https://dev.indiainsurancedeals.com/products-";
// const ProductLink = "https://dev.indiainsurancedeals.com/products";
// const ContactLink = "https://dev.indiainsurancedeals.com/contact";

// const truncateText = (text, maxLength) => {
//   if (text.length > maxLength) {
//     return text.substring(0, maxLength) + "...";
//   }
//   return text;
// };

// export const ChatBotResponse = ({ text, maxLength = 100, isBold = false }) => {
//   const [showMore, setShowMore] = useState(false);
  
//   const toggleShowMore = () => {
//     setShowMore(!showMore);
//   };

//   const truncatedText = truncateText(text, maxLength);
  
//   return (
//     <div>
//       {showMore ? (
//         <div className="chat-response-text">
//           {text}
//           <button onClick={toggleShowMore} className="show-more-button">
//             Show less
//           </button>
//         </div>
//       ) : (
//         <div className={isBold ? "bold-text" : ""}>
//           {truncatedText}
//           {text.length > maxLength && (
//             <button onClick={toggleShowMore} className="show-more-button">
//               Show more
//             </button>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export const analyze = (text, product) => {
 
//   console.log(product);
//   if (text) {
//     text = text.toLowerCase(); // Convert to lowercase if text is defined
//   }

//   if (
//     text &&
//     (text.startsWith("hi") ||
//       text.startsWith("hai") ||
//       text.startsWith("hello") ||
//       text.startsWith("hey"))
//   ) {
//     return ["Hi, How can I help you?"];
//   } else if (
//     text &&
//     (text.includes("what are insurances plans") ||
//       text.includes("types of insurances plans") ||
//       text.includes("insurances plans"))
//   ) {
//     return [
//       "Term Insurance With Premium",
//       "Term Insurance With No Premium",
//       "Child Education Plan",
//       "Child Marriage Plan",
//       "Life Long Income Plan",
//       "Lumpsum Plan",
//       "Pension Plan",
//       "Money Back Plan",
//       "Monthly Regular Income Plan",
//     ].map((e, index) => (
//       <ChatBotResponse key={index} text={e} maxLength={100} />
//     ));
//   } else {
//     const filteredData =
//       Data &&
//       Data.find((item) => {
//         return (
//           item.Product_Name && item.Product_Name.toLowerCase().includes(text)
//         );
//       });

//     if (filteredData) {
//       //ProductType Info
//       return (
//         <div className="product">
//           <ChatBotResponse
//             className="protext"
//             text={`Product Name: ${filteredData.Product_Name || ""}`}
            
//             isBold={true}
//           />
//           <ChatBotResponse
//             className="protext"
//             text={`Description: ${filteredData.productdescription || ""}`}
//             isBold={true}
//           />
//           <ChatBotResponse
//             className="protext"
//             text={`Benefits: ${filteredData.Benefits || ""}`}
//             isBold={true}
//           />
//           <p>
//             <Link
//               to={`https://dev.indiainsurancedeals.com/productdetails-${filteredData.id}`}
//             >
//               more info
//             </Link>
//           </p>
//           <p>
//             <Link to={ContactLink}>contact us</Link>
//           </p>
//         </div>
//       );
//     } else {
//       const planDetailsData =
//         Data1 &&
//         Data1.find((plan) => {
//           return (
//             plan.Product_Name && plan.Product_Name.toLowerCase().includes(text)
//           );
//         });

//       if (planDetailsData) {
//         //Products Info
//         return (
//           <div className="product">
//             <ChatBotResponse
//               className="ptext"
//               text={`Plan Name: ${planDetailsData.Product_Name || ""}`}
             
//               isBold={true}
//             />
//             <ChatBotResponse
//               className="ptext"
//               text={`Plan Description: ${planDetailsData.description || ""}`}
//               isBold={true}
//             />
//             <ChatBotResponse
//               className="ptext"
//               text={`Benefits: ${planDetailsData.benefits || ""}`}
//               isBold={true}
//             />
//             <p>
//               <Link
//                 to={`https://dev.indiainsurancedeals.com/products-${planDetailsData.id}`}
//               >
//                 more info
//               </Link>
//             </p>
//             <p>
//               <Link to={ContactLink}>contact us</Link>
//             </p>
//           </div>
//         );
//       } else {
//         // Fallback message when no specific response is found
//         return [
//           "Sorry, I didn't find any information for that. Please rephrase your question or ask something else.",
//         ];
//       }
//     }
//   }
// };




