//Bajja Allianz
import "./style.css";
import ace from "../../Data/products/life/ba/ace.pdf";
import awg_si from "../../Data/products/life/ba/awg_si.pdf";
import awg from "../../Data/products/life/ba/awg.pdf";
import etouch from "../../Data/products/life/ba/etouch.pdf";
import gpg from "../../Data/products/life/ba/gpg.pdf";
import hdfc_lsp from "../../Data/products/life/ba/HDFC-Life-Sanchay-Par-Advantage-Retail.pdf";
import hdfc_lsi from "../../Data/products/life/ba/HDFC-Life-SMART-INCOME-Brochure.pdf";
import lli from "../../Data/products/life/ba/lli.pdf";
import pp730 from "../../Data/products/life/ba/PP12201710730-HDFC-Life-Sampoorn-Samridhi-Plus-Retail-Brochure.pdf";
import pp731 from "../../Data/products/life/ba/PP12201710731-HDFC-Life-ClassicAssure-Plus-Retail-Brochure.pdf";
import pp734 from "../../Data/products/life/ba/PP12201710734-HDFC-Life-Super-Income-Plan.pdf";
import sfmp from "../../Data/products/life/ba/PP12201710734-HDFC-Life-Super-Income-Plan.pdf";
import spg from "../../Data/products/life/ba/spg.pdf";

// Bharati Axa life

import elite from "../../Data/products/life/bali/elite advantage .pdf";
import gwpp from "../../Data/products/life/bali/guarentee wealth pro plan.pdf";
import sep from "../../Data/products/life/bali/Super endownment plan.pdf";
import sp from "../../Data/products/life/bali/swabhimanm plan.pdf";
import unnatiplan from "../../Data/products/life/bali/unnati plan.pdf";

//HDFC life Insurance

import hli_spa from "../../Data/products/life/hl/HDFC-Life-Sanchay-Par-Advantage-Retail-Brochure.pdf";
import hli_sib from "../../Data/products/life/hl/HDFC-Life-SMART-INCOME-Brochure.pdf";
import hli_sip from "../../Data/products/life/hl/HDFC-Life-Super-Income-Plan.pdf";
import hli_pp730 from "../../Data/products/life/hl/PP12201710730-HDFC-Life-Sampoorn-Samridhi-Plus-Retail-Brochure (1).pdf";
import hli_pp731 from "../../Data/products/life/hl/PP12201710731-HDFC-Life-ClassicAssure-Plus-Retail-Brochure.pdf";
// import sfmp from "../../Data/products/life/hl/Sanchay-Fixed-Maturity-Plan.pdf";
import spar from "../../Data/products/life/hl/spar.pdf";

//Kotak Life Insurance

import eterm from "../../Data/products/life/kli/28_eterm.pdf";
import asp from "../../Data/products/life/kli/asp.pdf";
import fm from "../../Data/products/life/kli/fm.pdf";
import gfb from "../../Data/products/life/kli/gfb.pdf";
import gfb2 from "../../Data/products/life/kli/gfb2.pdf";
import gsp from "../../Data/products/life/kli/gsp.pdf";
import pi from "../../Data/products/life/kli/pi.pdf";
import ppp from "../../Data/products/life/kli/ppp.pdf";

//Punjab Nation Bank life insurance

import cp from "../../Data/products/life/pl/century plan.pdf";
import genius from "../../Data/products/life/pl/genius.pdf";
import mas from "../../Data/products/life/pl/mas.pdf";
import mgfp from "../../Data/products/life/pl/mgfp.pdf";
import mmtp from "../../Data/products/life/pl/mmtpplus.pdf";
import mssp from "../../Data/products/life/pl/mssp.pdf";

//State Bank of India Life Insuracne

import criticare from "../../Data/products/life/sbil/SBI LIFE INSURENCE/Criti-Care.pdf";
import elite_assure from "../../Data/products/life/sbil/SBI LIFE INSURENCE/elite-assure.pdf";
import guarentee_assure from "../../Data/products/life/sbil/SBI LIFE INSURENCE/guarantee-assure.pdf";
import life_secure from "../../Data/products/life/sbil/SBI LIFE INSURENCE/life-secure.pdf";
import lifelong from "../../Data/products/life/sbil/SBI LIFE INSURENCE/lifelong.pdf";
import lifestyle from "../../Data/products/life/sbil/SBI LIFE INSURENCE/lifeStyle-secure.pdf";

const PdfData = [
  {
    id: 25,
    pdf_link: ace,
  },
  {
    id: 23,
    pdf_link: awg_si,
  },
  {
    id: 22,
    pdf_link: awg,
  },
  {
    id: 20,
    pdf_link: etouch,
  },
  {
    id: 24,
    pdf_link: gpg,
  },
  {
    id: 16,
    pdf_link: hdfc_lsp,
  },
  {
    id: 19,
    pdf_link: hdfc_lsi,
  },
  {
    id: 26,
    pdf_link: lli,
  },
  {
    id: 11,
    pdf_link: pp730,
  },
  {
    id: 12,
    pdf_link: pp731,
  },
  {
    id: 19,
    pdf_link: pp734,
  },
  {
    id: 17,
    pdf_link: sfmp,
  },
  {
    id: 21,
    pdf_link: spg,
  },
  {
    id: 4,
    pdf_link: elite,
  },
  {
    id: 2,
    pdf_link: gwpp,
  },
  {
    id: 5,
    pdf_link: sep,
  },
  {
    id: 9,
    pdf_link: sp,
  },
  {
    id: 7,
    pdf_link: unnatiplan,
  },
  //HDFC Life Insurance
  {
    id: 16,
    pdf_link: hli_spa,
  },
  {
    id: 21,
    pdf_link: hli_sib,
  },
  {
    id: 15,
    pdf_link: hli_sip,
  },
  {
    id: 11,
    pdf_link: hli_pp730,
  },
  {
    id: 12,
    pdf_link: hli_pp731,
  },
  {
    id: 17,
    pdf_link: sfmp,
  },
  {
    id: 18,
    pdf_link: spar,
  },

  //Kotak Life Insurance
  {
    id: 28,
    pdf_link: eterm,
  },
  {
    id: 29,
    pdf_link: asp,
  },
  {
    id: 31,
    pdf_link: fm,
  },
  {
    id: 34,
    pdf_link: gfb,
  },
  {
    id: 34,
    pdf_link: gfb2,
  },
  {
    id: 33,
    pdf_link: gsp,
  },
  {
    id: 32,
    pdf_link: pi,
  },
  {
    id: 18, //pending
    pdf_link: ppp,
  },

  //Punjab National Bank Life Insurance

  {
    id: 46,
    pdf_link: cp,
  },
  {
    id: 43,
    pdf_link: genius,
  },
  {
    id: 42,
    pdf_link: mas,
  },
  {
    id: 44,
    pdf_link: mgfp,
  },
  {
    id: 41,
    pdf_link: mmtp,
  },
  {
    id: 45,
    pdf_link: mssp,
  },

  // State Bank of India Life Insurance

  {
    id: 18,
    pdf_link: criticare,
  },
  {
    id: 18,
    pdf_link: elite_assure,
  },
  {
    id: 18,
    pdf_link: guarentee_assure,
  },
  {
    id: 18,
    pdf_link: life_secure,
  },
  {
    id: 18,
    pdf_link: lifelong,
  },
  {
    id: 18,
    pdf_link: lifestyle,
  },
];

const PdfDownloads = ({ id }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    let pdf = PdfData.find((pdf) => {
      return pdf.id === id;
    });
    if (pdf) {
      link.href = pdf.pdf_link;
      link.download = pdf.pdf_link;
    } else {
      alert("Brochure will be available soon");
    }
    link.click();
    link.remove();
  };
  return (
    <>
      <button id="download-btn" type="button" onClick={handleDownload}>
        Brochure {"  "} <i className="fa-solid fa-download"></i>
      </button>
    </>
  );
};

export default PdfDownloads;
