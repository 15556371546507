import { useState, useEffect, Fragment } from "react";
import productData from "../../Data/Allroductdetails.json";
import "./style.css";

const CompanyBased = ({ c_id }) => {
  const [companyPlans, setCompanyPlans] = useState([]);

  useEffect(() => {
    // Filter the data and set it in the useEffect hook
    const filteredPlans = productData.filter(
      (product) => product.Company_Id === c_id.id
    );
    setCompanyPlans(filteredPlans);
  }, [c_id]);
  return (
    <>
      <div className="company-main-container">
        {companyPlans.map((c, i) => {
          return (
            <Fragment key={i}>
              <div className="each-product-type">
                <div className="productimage-type">
                  <img
                    src={c_id.company_Image}
                    alt=""
                    className="all-company-image"
                  />
                  <span className="c-product-name">{c.Product_Name}</span>
                </div>
                <div className="c-product-db">
                  <h6>Product Description :</h6>
                  <p>{c.productdescription}</p>
                  <h6>Product Benefits :</h6>
                  <p>
                    {c.Benefits.map((p, i) => {
                      return (
                        <Fragment key={i}>
                          <li>{p}</li>;
                        </Fragment>
                      );
                    })}
                  </p>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default CompanyBased;
