//Final Working code
// import React, { useRef, useState, useEffect } from 'react';
// import '../ChatBot/style.css';
// import ChatMessage from './ChatMessage';
// import { analyze } from './utils';
// import planData from "../../Data/planDetails.json";
// import allData from "../../Data/Allroductdetails.json";

// const Chatbot = () => {
//   const [searchValue, setSearchValue] = useState("");
//   const [rendersuggestion, setRendersuggestion] = useState(false);
//   const [searchSuggestion, setSearchsuggetions] = useState([]);
//   const [product, setProduct] = useState(null);
//   const [messages, setMessages] = useState([
//     {
//       message: "Hi, may I know your name?",
//     },
//   ]);

//   const [userNameProvided, setUserNameProvided] = useState(false);

//   const mergeData = [...planData, ...allData];

//   const [chatbotVisible, setChatBotVisible] = useState(true);

//   const toggleChatbotVisibility = () => {
//     setChatBotVisible(!chatbotVisible);
//   };

//   const suggestionHandler = ({ target: { value } }) => {
//     setSearchValue(value);
//     setRendersuggestion(userNameProvided && value.trim() !== "");

//     if (value.trim() !== "") {
//       const filteredData = mergeData.filter((p) => {
//         return (
//           p.Product_Name &&
//           p.Product_Name.toLowerCase().includes(value.toLowerCase())
//         );
//       });
//       setSearchsuggetions(filteredData);
//     } else {
//       setSearchsuggetions([]);
//     }
//   };

//   const messagesRef = useRef(null);
//   const chatMessagesRef = useRef(null);
//   const [text, setText] = useState();

//   const onSend = () => {
//     if (searchValue.length > 1) {
//       setText(searchValue);

//       if (
//         messages.length === 1 &&
//         messages[0].message === "Hi, may I know your name?"
//       ) {
//         setUserNameProvided(true);
//         setMessages([
//           ...messages,
//           {
//             message: `Nice to meet you, ${searchValue}! How can I assist you today?`,
//           },
//         ]);
//       } else if (searchSuggestion.length !== 0) {
//         setProduct(
//           mergeData.find((p) => {
//             return p.Product_Name.toLowerCase().includes(searchValue.toLowerCase());
//           })
//         );
//       } else {
//         analyze(
//           "Sorry, I didn't find any information for that. Please rephrase your question or ask something else."
//         );
//       }
//       setSearchValue("");
//       setSearchsuggetions([]);
//       let list = [...messages, { message: searchValue, user: true }];

//       if (list.length > 2) {
//         const reply = analyze(searchValue, product);

//         if (!reply) {
//           list = [
//             ...list,
//             {
//               message:
//                 "Sorry, we didn't get that. Can you please rephrase your question?",
//             },
//           ];
//         } else {
//           list = [...list, { message: reply }];
//         }
//       } else {
//         list = [
//           ...list,
//           {
//             message: `Hi, ${searchValue}`,
//           },
//           {
//             message: "How can I help you?",
//           },
//         ];
//       }

//       setMessages(list);
//       setText("");
//       scrollToBottom();
//     }
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setSearchValue(suggestion.Product_Name);
  
//     const clickedProduct = mergeData.find((p) => {
//       return p.Product_Name.toLowerCase().includes(suggestion.Product_Name.toLowerCase());
//     });
  
//     if (clickedProduct) {
//       const reply = analyze(suggestion.Product_Name, clickedProduct);
  
//       if (Array.isArray(reply)) {
//         setMessages([...messages, { message: suggestion.Product_Name, user: true }, ...reply]);
//       } else {
//         setMessages([...messages, { message: suggestion.Product_Name, user: true }, { message: reply }]);
//       }
//     } else {
//       setMessages([...messages, { message: "Sorry, information about this product is not available." }]);
//     }
  
//     setSearchValue("");
//     setSearchsuggetions([]);
//     scrollToBottom();
//   }

//   const scrollToBottom = () => {
//     if (chatMessagesRef.current) {
//       chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     if (searchValue.trim() !== "") {
//       const filteredData = mergeData.filter((p) => {
//         return (
//           p.Product_Name &&
//           p.Product_Name.toLowerCase().includes(searchValue.toLowerCase())
//         );
//       });
//       setSearchsuggetions(filteredData);
//     } else {
//       setSearchsuggetions([]);
//     }
//   }, [searchValue]);

//   if (!chatbotVisible) {
//     return (
//       <div className="chat-toggle-button" onClick={toggleChatbotVisibility}></div>
//     );
//   }

//   return (
//     <div className="chatbot-container">
//       <div className="chatbot-header">
//         <div className="image">
//           <div className='header-container'>
//             <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='aliceblue'>
//               {/* SVG Path Data */}
//             </svg>
//             <h5 className="heading">Bot-<span className='title'>IndiaInsuranceDeals.com </span></h5>
//             <button className='chatbot-close-button' onClick={toggleChatbotVisibility}>X</button>
//           </div>
//         </div>
//       </div>
//       <div className="chatmessage" ref={chatMessagesRef}>
//         {messages.length > 0 &&
//           messages.map((data) => <ChatMessage {...data} />)}

//         {rendersuggestion ? (
//           <div className="suggestion-container">
//             {searchSuggestion.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//               >
//                 {suggestion.Product_Name}
//               </li>
//             ))}
//           </div>
//         ) : null}
//         <div className="box-block">
//           <input
//             type="text"
//             className="form-control"
//             value={searchValue}
//             onChange={suggestionHandler}
//             onFocus={() => setRendersuggestion(true)}
//             onKeyPress={(e) => e.key === 'Enter' && onSend()}
//           />
//           <button type="submit" className="btn-send" onClick={onSend}>
//             Send
//           </button>
//         </div>
//         <div id="copyright" className='footer'>Copyrights reserved IIM.</div>
//       </div>
//     </div>
//   );
// };

// export default Chatbot;

import React, { useRef, useState, useEffect } from 'react';
import '../ChatBot/style.css';
import ChatMessage from './ChatMessage';
import { analyze } from './utils';
import planData from "../../Data/planDetails.json";
import allData from "../../Data/Allroductdetails.json";

const Chatbot = () => {
  const [searchValue, setSearchValue] = useState("");
  const [rendersuggestion, setRendersuggestion] = useState(false);
  const [searchSuggestion, setSearchsuggetions] = useState([]);
  const [product, setProduct] = useState(null);
  const [messages, setMessages] = useState([
    {
      message: "Hi, may I know your name?",
    },
  ]);

  const [userNameProvided, setUserNameProvided] = useState(false);

  const mergeData = [...planData, ...allData];

  const [chatbotVisible, setChatBotVisible] = useState(true);

  const toggleChatbotVisibility = () => {
    setChatBotVisible(!chatbotVisible);
  };

  const suggestionHandler = ({ target: { value } }) => {
    setSearchValue(value);
    setRendersuggestion(userNameProvided && value.trim() !== "");

    if (value.trim() !== "") {
      const filteredData = mergeData.filter((p) => {
        return (
          p.Product_Name &&
          p.Product_Name.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchsuggetions(filteredData);
    } else {
      setSearchsuggetions([]);
    }
  };

  const messagesRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const [text, setText] = useState();
  const onSend = async () => {
    // const chatData = {
    //   userName: searchValue,
    //   userMessages: messages
    //     .filter(({ user }) => user)
    //     .map(({ message }) => ({ message, user: true, clicked: false })), // clicked field added
    //   suggestions: searchSuggestion.map(({ Product_Name }) => ({ message: Product_Name, user: false, clicked: false })),
    // };
  
    // try {
    //   // Send data to the API endpoint
    //   const response = await fetch('http://localhost:8000/botresponse', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ botresponse: [chatData] }),
    //   });
  
    //   // Handle the response if needed
    //   console.log('Chat history sent successfully:', response);
    // } catch (error) {
    //   console.error('Error sending chat history:', error);
    // }
  
    if (searchValue.length > 1) {
      setText(searchValue);
  
      if (
        messages.length === 1 &&
        messages[0].message === "Hi, may I know your name?"
      ) {
        setUserNameProvided(true);
        setMessages([
          ...messages,
          {
            message: `Nice to meet you, ${searchValue}! How can I assist you today?`,
          },
        ]);
      } else if (searchSuggestion.length !== 0) {
        const allSuggestionsData = [];
  
        for (const suggestion of searchSuggestion) {
          const suggestionData = mergeData.find((p) => {
            return p.Product_Name.toLowerCase().includes(suggestion.Product_Name.toLowerCase());
          });
  
          if (suggestionData) {
            try {
              const reply = await analyze(suggestion.Product_Name, suggestionData);
              allSuggestionsData.push({ message: suggestion.Product_Name, user: true }, ...reply);
            } catch (error) {
              console.error(`Error fetching suggestion data for ${suggestion.Product_Name}:`, error);
              allSuggestionsData.push({ message: `Error fetching data for ${suggestion.Product_Name}.` });
            }
          } else {
            allSuggestionsData.push({ message: `Sorry, information about ${suggestion.Product_Name} is not available.` });
          }
        }
  
        setMessages([...messages, ...allSuggestionsData]);
      } else {
        try {
          const reply = await analyze(
            "Sorry, I didn't find any information for that. Please rephrase your question or ask something else."
          );
          setMessages([...messages, reply]);
        } catch (error) {
          console.error("Error analyzing message:", error);
          setMessages([...messages, { message: "Error analyzing message." }]);
        }
      }
  
      setSearchValue("");
      setSearchsuggetions([]);
      scrollToBottom();
    }
  };
  
  const handleSuggestionClick = async (suggestion) => {
    setSearchValue(suggestion.Product_Name);
  
    const clickedProduct = mergeData.find((p) => {
      return p.Product_Name.toLowerCase().includes(suggestion.Product_Name.toLowerCase());
    });
  
    if (clickedProduct) {
      const reply = await analyze(suggestion.Product_Name, clickedProduct);
  
      if (Array.isArray(reply)) {
        setMessages([...messages, { message: suggestion.Product_Name, user: true }, ...reply]);
      } else {
        setMessages([...messages, { message: suggestion.Product_Name, user: true }, { message: reply }]);
      }
    } else {
      setMessages([...messages, { message: "Sorry, information about this product is not available." }]);
    }
  
    setSearchValue("");
    setSearchsuggetions([]);
    scrollToBottom();
  }

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (searchValue.trim() !== "") {
      const filteredData = mergeData.filter((p) => {
        return (
          p.Product_Name &&
          p.Product_Name.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setSearchsuggetions(filteredData);
    } else {
      setSearchsuggetions([]);
    }
  }, [searchValue]);

  if (!chatbotVisible) {
    return (
      <div className="chat-toggle-button" onClick={toggleChatbotVisibility}></div>
    );
  }

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <div className="image">
          <div className='header-container'>
          <svg xmlns="http://www.w3.org/2000/svg" className='logo' height="1em" viewBox="0 0 640 512" fill='aliceblue'>
          <path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5-48 272V224h16z" />
        </svg>
            <h5 className="heading">     Bot-<span className='title'>IndiaInsuranceDeals.com </span></h5>
            <button className='chatbot-close-button' onClick={toggleChatbotVisibility}>X</button>
          </div>
        </div>
      </div>
      <div className="chatmessage" ref={chatMessagesRef}>
        {messages.length > 0 &&
          messages.map((data) => <ChatMessage {...data} />)}

        {rendersuggestion ? (
          <div className="suggestion-container">
            {searchSuggestion.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.Product_Name}
              </li>
            ))}
          </div>
        ) : null}
        <div className="box-block">
          <input
            type="text"
            className="form-control"
            value={searchValue}
            onChange={suggestionHandler}
            onFocus={() => setRendersuggestion(true)}
            onKeyPress={(e) => e.key === 'Enter' && onSend()}
          />
          <button type="submit" className="btn-send" onClick={onSend}>
            Send
          </button>
        </div>
        <div id="copyright" className='footer'>Copyrights reserved IIM.</div>
      </div>
    </div>
  );
};

export default Chatbot;
































// import React, { useRef, useState } from 'react';
// import '../ChatBot/style.css';
// import ChatMessage from './ChatMessage';
// import { analyze } from './utils';
// import planData from "../../Data/planDetails.json";
// import allData from "../../Data/Allroductdetails.json";


// const Chatbot = () => {
//   const [searchValue, setSearchValue] = useState("");
//   const [rendersuggestion, setRendersuggestion] = useState(false);
//   const [searchSuggestion, setSearchsuggetions] = useState([]);
//   const [product, setProduct] = useState()
//   const [messages, setMessages] = useState([
//     {
//       message: "Hi, may I know your name?",
//     },
//   ]);

//   const [userNameProvided, setUserNameProvided] = useState(false);

//   const mergeData = [...planData, ...allData];

//   const [chatbotVisible, setChatBotVisible] = useState(true);

//   const toggleChatbotVisibility = () =>{
//     setChatBotVisible(!chatbotVisible)
//   }

//   // const suggestions = [
//   //   "You can ask about our products",
//   //   "Life Insurance Plans",
//   //   "Health Insurance Plans",
//   //   "General Insurance Plans",
//   // ];

//   const suggestionHandler = ({ target: { value } }) => {
//     setSearchValue(value);
//     setRendersuggestion(userNameProvided && value.trim() !== "");

//     if (value.trim() !== "") {
//       const filteredData = mergeData.filter((p) => {
//         return (
//           p.Product_Name &&
//           p.Product_Name.toLowerCase().includes(value.toLowerCase())
//         );
//       });
//       setSearchsuggetions(filteredData);
//     }else{
//       setSearchsuggetions([]);
//     }
//   };
//   const messagesRef = useRef(null);
//   const chatMessagesRef = useRef(null);
//   const [text, setText] = useState();
//   const onSend = () => {
//     if (searchValue.length > 1) {
//       setText(searchValue)

//       if (messages.length === 1 && messages[0].message === "Hi, may I know your name?") {
//         setUserNameProvided(true);
//         setMessages([
//           ...messages,
//           { message: `Nice to meet you, ${searchValue}! How can I assist you today?` },
//         ]);
//       } else if (searchSuggestion.length !== 0) {
//         setProduct(mergeData.find((p) => {
//           return p.Product_Name.toLowerCase().includes(searchValue.toLowerCase());
//         }));
//       }
//       else {
//         analyze("Sorry, I didn't find any information for that. Please rephrase your question or ask something else.",
//         )
//       }
//       setSearchValue("");
//       setSearchsuggetions([]);
//       let list = [...messages, { message: searchValue, user: true }];

//       if (list.length > 2) {
//         const reply = analyze(searchValue, product);

//         // Check if the reply is empty (no meaningful response)
//         if (!reply) {
//           list = [
//             ...list,
//             {
//               message: "Sorry, we didn't get that. Can you please rephrase your question?",
//             },
//           ];
//         } else {
//           list = [...list, { message: reply }];
//         }
//       } else {
//         list = [
//           ...list,
//           {
//             message: `Hi, ${searchValue}`,
//           },
//           {
//             message: "How can I help you?",
//           },
//           {
//             // message: (
//               // <div className="suggestions">
//               //   {suggestions.map((suggestion, index) => (
//               //     <div
//               //       key={index}
//               //       className="suggestion"
//               //       onClick={() => handleSuggestionClick(suggestion)}
//               //     >

//               //       {suggestion}
//               //     </div>
//               //   ))}
//               // </div>
//             // ),
//           },
//         ];
//       }

//       setMessages(list);
//       setText("");
//       scrollToBottom();
//     }
//   };
//   function handleSuggestionClick(suggestion) {
//     setRendersuggestion(false)
//     setSearchValue(suggestion.Product_Name)
//     onSend()
//     // const suggestionText = suggestion.product_Name;
//     // const relatedData = planData.find((p) =>
//     //   p.product_Name.toLowerCase() === suggestionText.toLowerCase()
//     // );
//     // if (relatedData) {
//     //   const chatResponse = `Here is the information about ${relatedData.product_Name}: ${relatedData.description}`;
//     //   setMessages([...messages, { message: suggestionText, user: true }, { message: chatResponse }]);
//     //   scrollToBottom();
//     // }
//   };
//   const scrollToBottom = () => {
//     if (chatMessagesRef.current) {
//       chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
//     }
//   };

//   if (!chatbotVisible) {
//     return(
//       <div className="chat-toggle-button" onClick={toggleChatbotVisibility}>
//       </div>
//     )
//   }

//   return (
//     <div className="chatbot-container">
//       <div className="chatbot-header">
        
      
//       <div className="image">
//         <div className='header-container'>
//         <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='aliceblue'>
//           <path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5-48 272V224h16z" />
//         </svg>
//         <h5 className="heading">Bot-<span className='title'>IndiaInsuranceDeals.com </span></h5>
//         <button className='chatbot-close-button' onClick={toggleChatbotVisibility}>X</button>
//         </div>
//       </div>
//       </div>
//       <div className="chatmessage" ref={chatMessagesRef}>
//         {messages.length > 0 &&
//           messages.map((data) => <ChatMessage {...data} />)}

// {rendersuggestion ? (
//           <div className="suggestion-container">
//             {searchSuggestion.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//               >
//                 {suggestion.Product_Name}
//               </li>
//             ))}
//           </div>
//         ) : null}
//         <div className="box-block">
//           <input
//             type="text"
//             className="form-control"
//             value={searchValue}
//             onChange={suggestionHandler}
//             onFocus={() => setRendersuggestion(true)}
//             onClick={onSend}
//             onKeyPress={(e) => e.key === 'Enter' && onSend()}
//           // onBlur={() => setRendersuggestion(false)}
//           />
//           <button type="submit" className="btn-send" onClick={onSend}>
//             Send
//           </button>
//         </div>
      
//         <div id="copyright" className='footer'>Copyrights reserved IIM.</div>
//       </div>
//     </div>
//   ); 
// };

// export default Chatbot;




// import React, { useRef, useState } from 'react';
// import '../ChatBot/style.css';
// import ChatMessage from './ChatMessage';
// import { analyze } from './utils';
// import planData from "../../Data/planDetails.json";
// import allData from "../../Data/Allroductdetails.json";

// const Chatbot = () => {
//   const [searchValue, setSearchValue] = useState("");
//   const [rendersuggestion, setRendersuggestion] = useState(false);
//   const [searchSuggestion, setSearchsuggetions] = useState([]);
//   const [product, setProduct] = useState();
//   const [messages, setMessages] = useState([
//     {
//       message: "Hi, may I know your name?",
//     },
//   ]);

//   const [userNameProvided, setUserNameProvided] = useState(false);

//   const mergeData = [...planData, ...allData];

//   const [chatbotVisible, setChatBotVisible] = useState(true);

//   const toggleChatbotVisibility = () => {
//     setChatBotVisible(!chatbotVisible);
//   };

//   const suggestionHandler = ({ target: { value } }) => {
//     setSearchValue(value);
//     setRendersuggestion(userNameProvided && value.trim() !== "");

//     if (value.trim() !== "") {
//       const filteredData = mergeData.filter((p) => {
//         return (
//           p.Product_Name &&
//           p.Product_Name.toLowerCase().includes(value.toLowerCase())
//         );
//       });
//       setSearchsuggetions(filteredData);
//     } else {
//       setSearchsuggetions([]);
//     }
//   };

//   const messagesRef = useRef(null);
//   const chatMessagesRef = useRef(null);
//   const [text, setText] = useState();

//   const onSend = () => {
//     if (searchValue.length > 1) {
//       setText(searchValue);

//       if (
//         messages.length === 1 &&
//         messages[0].message === "Hi, may I know your name?"
//       ) {
//         setUserNameProvided(true);
//         setMessages([
//           ...messages,
//           {
//             message: `Nice to meet you, ${searchValue}! How can I assist you today?`,
//           },
//         ]);
//       } else if (searchSuggestion.length !== 0) {
//         setProduct(
//           mergeData.find((p) => {
//             return p.Product_Name.toLowerCase().includes(searchValue.toLowerCase());
//           })
//         );
//       } else {
//         analyze(
//           "Sorry, I didn't find any information for that. Please rephrase your question or ask something else."
//         );
//       }
//       setSearchValue("");
//       setSearchsuggetions([]);
//       let list = [...messages, { message: searchValue, user: true }];

//       if (list.length > 2) {
//         const reply = analyze(searchValue, product);

//         if (!reply) {
//           list = [
//             ...list,
//             {
//               message:
//                 "Sorry, we didn't get that. Can you please rephrase your question?",
//             },
//           ];
//         } else {
//           list = [...list, { message: reply }];
//         }
//       } else {
//         list = [
//           ...list,
//           {
//             message: `Hi, ${searchValue}`,
//           },
//           {
//             message: "How can I help you?",
//           },
//         ];
//       }

//       setMessages(list);
//       setText("");
//       scrollToBottom();
//     }
//   };

//   function handleSuggestionClick(suggestion) {
//     setRendersuggestion(false);
//     setSearchValue(suggestion.Product_Name);

//     const clickedProduct = mergeData.find((p) => {
//       return p.Product_Name.toLowerCase().includes(suggestion.Product_Name.toLowerCase());
//     });

//     if (clickedProduct) {
//       const chatResponse = `Here is the information about ${clickedProduct.Product_Name}: ${clickedProduct.description}`;
//       setMessages([...messages, { message: suggestion.Product_Name, user: true }, { message: chatResponse }]);
//     } else {
//       setMessages([...messages, { message: "Sorry, information about this product is not available." }]);
//     }

//     scrollToBottom();
//   }

//   const scrollToBottom = () => {
//     if (chatMessagesRef.current) {
//       chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
//     }
//   };

//   if (!chatbotVisible) {
//     return (
//       <div className="chat-toggle-button" onClick={toggleChatbotVisibility}></div>
//     );
//   }

//   return (
//     <div className="chatbot-container">
//       <div className="chatbot-header">
//         <div className="image">
//           <div className='header-container'>
//             <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='aliceblue'>
//               {/* SVG Path Data */}
//             </svg>
//             <h5 className="heading">Bot-<span className='title'>IndiaInsuranceDeals.com </span></h5>
//             <button className='chatbot-close-button' onClick={toggleChatbotVisibility}>X</button>
//           </div>
//         </div>
//       </div>
//       <div className="chatmessage" ref={chatMessagesRef}>
//         {messages.length > 0 &&
//           messages.map((data) => <ChatMessage {...data} />)}

//         {rendersuggestion ? (
//           <div className="suggestion-container">
//             {searchSuggestion.map((suggestion, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleSuggestionClick(suggestion)}
//               >
//                 {suggestion.Product_Name}
//               </li>
//             ))}
//           </div>
//         ) : null}
//         <div className="box-block">
//           <input
//             type="text"
//             className="form-control"
//             value={searchValue}
//             onChange={suggestionHandler}
//             onFocus={() => setRendersuggestion(true)}
//             onClick={onSend}
//             onKeyPress={(e) => e.key === 'Enter' && onSend()}
//           />
//           <button type="submit" className="btn-send" onClick={onSend}>
//             Send
//           </button>
//         </div>

//         <div id="copyright" className='footer'>Copyrights reserved IIM.</div>
//       </div>
//     </div>
//   );
// };

// export default Chatbot;
