import { useContext, memo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { planData } from "../ContextApi";
import Highlights from "../Highlights";
import FooterComponent from "../Footer/index";
import PdfDownloads from "../PdfDownloads/index";
// import FilterComponent from "../Filters";
import "./style.css";
import CustomModal from "../Modal";

const Products = () => {
  const navigateto = useNavigate();
  const { csvData, planDetails, productDetailHandler, setProductId } =
    useContext(planData);
  const naviagteToproduct = (id, companylogo) => {
    productDetailHandler(id, companylogo);
    setProductId(id);
    navigateto(`/productdetails-${id}`);
  };
  return (
    <>
      <div className="product-main-container">
        <div className="Product-details-container">
          <div className="product-highlights-container">
            <div className="product-details">
              <span className="product-type">{planDetails.Product_Name}</span>
              <h5 className="product-headings">Description</h5>
              <p className="details-description">{planDetails.description}</p>
              <h5 className="product-headings">Benefits</h5>
              <p className="details-description">{planDetails.benefits}</p>
            </div>
            <div>{<Highlights />}</div>
          </div>
          {/* <div className="filter-container">{<FilterComponent />}</div> */}
          <div className="table-container">
            {csvData.map((p, index) => {
              return (
                <Fragment key={index}>
                  <div className="products-card">
                    <div>
                      <img
                        src={p.Image_Id}
                        alt="comapany-logo"
                        className="company-logo-image"
                      ></img>
                    </div>
                    <div className="productname">
                      <h6>Product Name</h6>
                      <span>{p.Product_Name}</span>
                    </div>
                    <div>
                      <h6>Min Age - Max Age</h6>
                      <span>
                        {p.Min_age} - {p.Max_age}
                      </span>
                    </div>
                    <div>
                      <h6>Policy Term</h6>
                      <span>{p.Policy_Term}</span>
                    </div>
                    <div>
                      <h6>Sum Assured</h6>
                      <span>{p.SumAssured}</span>
                    </div>
                    <div>
                      <h6>Claim status</h6>
                      <span>{p.Claim_status}</span>
                    </div>

                    <div>
                      <h6>Riders</h6>
                      <span>{p.Riders}</span>
                    </div>
                    <div>
                      <h6>Premium Paying Term</h6>
                      <span>{p.Premium_paying_Term}</span>
                    </div>
                    {/* <div>
                      <h6>{planDetails.returns}</h6>
                      <span>15,00000 - at 4%</span>
                      <span>15,00000 - at 8%</span>
                    </div> */}
                    <div>
                      <PdfDownloads id={p.Id} />
                    </div>
                    <div>
                      <button
                        className="knowmore"
                        onClick={() => naviagteToproduct(p.Id, p.Image_Id)}
                      >
                        Know More{" "}
                        <i className="fa-solid fa-angle-up fa-rotate-90"></i>
                      </button>
                    </div>
                    <div className="buy-btn">
                      <CustomModal productname={p.Product_Name} />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      <FooterComponent />
    </>
  );
};

export default memo(Products);
