import React, { Component } from "react";
import Slider from "react-slick";
import "./style.css";
const newsDetails = [
  {
    id: 1,
    image_Url:
      "https://wpassets.adda247.com/wp-content/uploads/multisite/2023/01/27134549/Types-of-Insurance.png",
  },
  {
    id: 2,
    image_Url:
      "https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg",
  },
  {
    id: 1,
    image_Url:
      "https://wpassets.adda247.com/wp-content/uploads/multisite/2023/01/27134549/Types-of-Insurance.png",
  },
  {
    id: 2,
    image_Url:
      "https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg",
  },
  {
    id: 1,
    image_Url:
      "https://wpassets.adda247.com/wp-content/uploads/multisite/2023/01/27134549/Types-of-Insurance.png",
  },
  {
    id: 2,
    image_Url:
      "https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg",
  },
];

export default class InsuranceTypes extends Component {
  render() {
    var settings = {
      dots: true,
      initialSlide: 0,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 10000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="mainhighlights-main-container">
        <h3>Main Highlights</h3>
        <Slider {...settings} className="main-slider-container">
          {newsDetails.map(() => {
            return (
              <div>
                <img
                  src="https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg"
                  alt=""
                  className="main-highlights-image"
                />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
