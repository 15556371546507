import React, { useContext, memo, Fragment } from "react";
// import { useNavigate } from "react-router-dom";
import { planData } from "../ContextApi";
import Highlights from "../Highlights";
import FooterComponent from "../Footer/index";

//health Images
import starhealth from "../../Assets/healthImages/star-health.png";
import tataaia from "../../Assets/healthImages/tata-aia.webp";
import carehealth from "../../Assets/healthImages/care-health.png";
import manipal from "../../Assets/healthImages/manipal.jpg";
import iciclam from "../../Assets/healthImages/ICICI-Lombard.png";
import relience from "../../Assets/healthImages/relience.jpg";
import nivabupa from "../../Assets/healthImages/nivabupa.png";
import hdfc from "../../Assets/healthImages/hdfcergo.jpg";
import national from "../../Assets/healthImages/national.webp";
import adityabirla from "../../Assets/Aditya_Birla.png";
import CustomModal from "../Modal";
const company_Images = [
  { id: 1, path: iciclam },
  { id: 2, path: manipal },
  { id: 3, path: tataaia },
  { id: 4, path: carehealth },
  { id: 5, path: adityabirla },
  { id: 6, path: starhealth },
  { id: 7, path: hdfc },
  { id: 8, path: relience },
  { id: 9, path: national },
  { id: 10, path: nivabupa },
];
const HealthInsurance = () => {
  // const navigateto = useNavigate();
  const { csvData, planDetails, planId } = useContext(planData);

  const naviagteToproduct = (id) => {
    // productDetailHandler(id);
    // navigateto("/productdetails");
  };

  const handleDownload = (pdflink) => {
    const link = document.createElement("a");

    if (pdflink) {
      link.href = pdflink;
      link.download = pdflink;
    } else {
      alert("Brochure will be available soon");
    }
    link.click();
    link.remove();
  };

  const healthproducts = csvData.filter((data) => {
    return data[planId] === parseInt(planId.split("_")[0]);
  });
  return (
    <>
      <div className="health-main-container">
        <div className="product-main-container">
          <div className="Product-details-container">
            <div className="product-highlights-container">
              <div className="product-details">
                <span className="product-type">{planDetails.Product_Name}</span>
                <h5 className="product-headings">Description</h5>
                <p className="details-description">{planDetails.description}</p>
                <h5 className="product-headings">Benefits</h5>
                <p className="details-description">{planDetails.benefits}</p>
              </div>
              <div>
                <Highlights />
              </div>
            </div>
            <div className="table-container">
              {healthproducts.map((p, index) => (
                <Fragment key={index}>
                  <div className="products-card">
                    <div className="row-1">
                      <img
                        src={
                          company_Images.find((image) => image.id === p.CI_Id)
                            ?.path
                        }
                        alt="company-logo"
                        className="company-logo-image"
                      />
                    </div>
                    <div className="row-1">
                      <h6>Product Name</h6>
                      <span>{p.Product_Name}</span>
                    </div>
                    <div className="row-1">
                      <h6>Network hospitals</h6>
                      <span>{p.Network_hospitals}</span>
                    </div>
                    <div>
                      <h6>Premium paying Term</h6>
                      <span>{p.Premium_paying_Term}</span>
                    </div>
                    <div className="row-1">
                      <h6>Policy Term</h6>
                      <span>{p.Policy_Term}</span>
                    </div>
                    <div>
                      <h6>Family Floater</h6>
                      <span>{p.Family_Floater}</span>
                    </div>
                    <div>
                      <h6>No.of Dependents</h6>
                      <span>{p.No_of_Dependents}</span>
                    </div>
                    <div className="row-1">
                      <h6>SumAssured</h6>
                      <span>{p.SumAssured}</span>
                    </div>
                    <div className="row-1">
                      <button
                        id="download-btn"
                        onClick={() => handleDownload(p.Google_Drive_link)}
                      >
                        Brochure
                      </button>
                    </div>
                    <div>
                      <button
                        className="knowmore"
                        onClick={() => {
                          naviagteToproduct(p.Id);
                        }}
                      >
                        Know More{" "}
                        <i className="fa-solid fa-angle-up fa-rotate-90"></i>
                      </button>
                    </div>
                    <div className="but-btn">
                      <CustomModal productname={p.Product_Name} />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default memo(HealthInsurance);
