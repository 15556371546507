import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./Components/Navbar";
import HomeComponent from "./Pages/Home/index";
import AboutComponent from "./Pages/About";
import SignupComponent from "./Pages/Signup";
import LoginComponent from "./Pages/Login";
import "./App.css";
import Contactus from "./Pages/Contact";
import ProductDetails from "./Components/Productdetails";
import Products from "./Components/Products";
import Healthproducts from "./Components/Healthproducts";
import Dropdown from "./Components/AllProducts";
import Generalproducts from "./Components/GeneralProducts";
import { planData } from "./Components/ContextApi";
import UserProfile from "./Pages/Profile";
import WorkwithApi from "./Pages/apiTester";
import Test from "./Components/Products/test";
import Deta from "./Components/Products/test2";
const App = () => {
  const { planId, productId, userId } = useContext(planData);

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/about" element={<AboutComponent />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/signup" element={<SignupComponent />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path={`/products-${planId}`} element={<Products />} />
          <Route path={`/profile-${userId}`} element={<UserProfile />} />
          <Route path={"api"} element={<WorkwithApi />} />
          <Route
            path={`/productdetails-${productId ? productId : planId}`}
            element={<ProductDetails />}
          />
          <Route
            path={`/healthproducts-${planId}`}
            element={<Healthproducts />}
          />
          <Route
            path={`/generalproducts-${planId}`}
            element={<Generalproducts />}
          />
          <Route path={`/test`} element={<Test />} />
          <Route path={`/test2/:id`} element={<Deta />} />
          <Route path="/allproducts" element={<Dropdown />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
