import React, { Component } from "react";
import Slider from "react-slick";
import "./style.css";
const newsDetails = [
  {
    thumbnail:
      "https://ibsintelligence.com/wp-content/uploads/2023/10/SBI-life.jpg",
    description: "SBI Life Insurances",
    activation: "False",
    id: 1,
  },
  {
    thumbnail:
      "https://www.livemint.com/lm-img/img/2023/06/14/600x338/2-0-117660545-BSE-4C-0_1681908040126_1686719010544_1686719017771.jpg",
    description:
      "Life Insurance Corporation of India shares price Today Live Updates : Life Insurance Corporation of India closed today at ₹648.4, up 0.52% from yesterday's ₹645.05",
    activation: "True",
    id: 2,
  },
  {
    thumbnail:
      "https://images.moneycontrol.com/static-mcnews/2023/04/Insurance-2-770x433.png?impolicy=website&width=770&height=431",
    description:
      "Max Life and SBI Life took charge in market share improvement for the month of August. Max Life gained 142 basis points YoY to 6.2 percent market share in the individual APE segment, while SBI Life gained 259 basis points to 17.6 percent.",
    activation: "True",
    id: 3,
  },
];

export default class InsuranceTypes extends Component {
  render() {
    var settings = {
      dots: true,
      initialSlide: 0,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 10000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="news-main-container">
        <h3>NEWS </h3>
        <Slider {...settings} className="news-slider-container">
          {newsDetails.map((n) => {
            return (
              <div className="image-container">
                <img src={n.thumbnail} alt="" className="news-types-image" />
                <p>{n.description}</p>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
