import React, { Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./style.css";

const Insurances = [
  {
    id: 1,
    image_Url:
      "https://wpassets.adda247.com/wp-content/uploads/multisite/2023/01/27134549/Types-of-Insurance.png",
  },
  {
    id: 2,
    image_Url:
      "https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg",
  },
  {
    id: 3,
    image_Url:
      "https://wpassets.adda247.com/wp-content/uploads/multisite/2023/01/27134549/Types-of-Insurance.png",
  },
  {
    id: 4,
    image_Url:
      "https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg",
  },
  {
    id: 5,
    image_Url:
      "https://wpassets.adda247.com/wp-content/uploads/multisite/2023/01/27134549/Types-of-Insurance.png",
  },
  {
    id: 6,
    image_Url:
      "https://www.paisabazaar.com/wp-content/uploads/2018/10/type-of-life-inurance-policy.jpg",
  },
];

const InsuranceTypes = () => {
  const settings = {
    dots: true,
    initialSlide: 0,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 10000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="insurance-main-container">
        <h3>Insurance types </h3>
        <Slider {...settings} className="insurance-slider-container">
          {Insurances.map((insurance) => (
            <Fragment key={insurance.id}>
              <div>
                <img
                  src={insurance.image_Url}
                  alt=""
                  className="insurance-highlights-image"
                />
              </div>
            </Fragment>
          ))}
        </Slider>
      </div>
      <div className="all-products">
        <Link to="/allproducts"> View All Products</Link>
      </div>
    </>
  );
};

export default InsuranceTypes;
