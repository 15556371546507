import { Fragment, useState, memo } from "react";
import { useContext } from "react";
import { planData } from "../ContextApi";
import FooterComponent from "../Footer/index";
import "./style.css";
import PdfDownloads from "../PdfDownloads";

const buttons = [
  {
    id: "description",
    title: "Description",
  },
  {
    id: "benefits",
    title: "Benefits",
  },
  {
    id: "highlights",
    title: "Highlights",
  },
  {
    id: "faq",
    title: "FAQ",
  },
];

const ProductDetails = () => {
  const { product, companyLogo } = useContext(planData);
  const [details, setDetails] = useState(product.productdescription);
  const [activeButton, setActiveButton] = useState("description");

  const detailsHandler = (id) => {
    setActiveButton(id);
    if (id === "description") {
      setDetails(product.productdescription);
    } else if (id === "benefits") {
      setDetails(product);
    } else if (id === "highlights") {
      setDetails(product.Highlights);
    } else if (id === "faq") {
      setDetails("");
    }
  };
  return (
    <>
      <div className="product-details-container">
        <div className="product-outer-container">
          <div className="product-buttons">
            {buttons.map((btn) => {
              return (
                <Fragment key={btn.id}>
                  <button
                    className={`product-btn ${
                      btn.id === activeButton ? "active" : ""
                    }`}
                    onClick={() => detailsHandler(btn.id)}
                  >
                    {btn.title}
                  </button>
                </Fragment>
              );
            })}
          </div>
          <div className="details-of-product">
            <h4 className="product-name">{product.Product_Name}</h4>
            <img src={companyLogo} alt="" />
          </div>
          <div className="product-info-container">
            {activeButton === "benefits" ? (
              <ul className="ul.my-list">
                {details.Benefits.map((benefit, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{benefit}</li>
                    </Fragment>
                  );
                })}
              </ul>
            ) : (
              <p className="product-summary">{details}</p>
            )}
          </div>
          <div className="product-download">
            <PdfDownloads id={product.id} />
          </div>
        </div>
        <div className="product-footer-container">
          <FooterComponent />
        </div>
      </div>
    </>
  );
};

export default memo(ProductDetails);
