import React, { Fragment, useState } from "react";
import "./style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const InputFields = [
  {
    label: "First Name",
    type: "text",
    input: "first_name",
    maxLength: 100,
    required: true,
  },
  {
    label: "Middle Name",
    type: "text",
    input: "middle_name",
    maxLength: 100,
    required: false,
  },
  {
    label: "Last Name",
    type: "text",
    input: "last_name",
    maxLength: 100,
    required: true,
  },
  {
    label: "Email",
    type: "email",
    input: "email_id",
    maxLength: 100,
    required: true,
  },
  {
    label: "Password",
    type: "password",
    input: "password",
    maxLength: 100,
    required: true,
  },
  {
    label: "Mobile Number",
    type: "text",
    input: "mobile_number",
    pattern: "[0-9]{1,10}",
    maxLength: 14,
    required: true,
  },
  {
    label: "Home Number",
    type: "text",
    input: "home_number",
    pattern: "[0-9]{1,10}",
    maxLength: 14,
    required: false,
  },
  {
    label: "Office Number",
    type: "text",
    input: "office_number",
    pattern: "[0-9]{1,10}",
    maxLength: 14,
    required: false,
  },

  {
    label: "House Number",
    type: "text",
    input: "house_number",
    maxLength: 100,
    required: false,
  },
  {
    label: "Address Line 1",
    type: "text",
    input: "address_line_1",
    maxLength: 100,
    required: false,
  },
  {
    label: "Address Line 2",
    type: "text",
    input: "address_line_2",
    maxLength: 100,
    required: false,
  },
  {
    label: "State",
    type: "text",
    input: "state",
    maxLength: 20,
    required: false,
  },
  {
    label: "City",
    type: "text",
    input: "city",
    maxLength: 30,
    required: false,
  },
  {
    label: "Country",
    type: "text",
    input: "country",
    maxLength: 20,
    required: false,
  },
  {
    label: "Pin Code",
    type: "text",
    input: "pin_code",
    maxLength: 10,
    required: false,
  },
];

const SignupComponent = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const tablename = {
    tbname: "customer",
    propname: "customer",
    is_email_verified: true,
    is_mobile_verified: true,
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData, ...tablename };

    updatedFormData[name] = value;

    setFormData(updatedFormData);

    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    const errors = {};
    InputFields.forEach((input) => {
      if (input.required && !formData[input.input]) {
        errors[input.input] = `${input.label} is required.`;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSignup = async () => {
    if (!validateForm()) {
      return;
    } else {
      const apiUrl = "https://api.indiainsurancedeals.com/v1/common/create.php";

      axios
        .post(apiUrl, JSON.stringify(formData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          alert("Registered Successfully please Login");
          navigate("/login");
        })
        .catch((error) => {
          alert("Failed to register Please try again after sometime");
        });
    }
  };
  return (
    <div className="signup-container">
      <div className="signup-sub-sontainer">
        <h2>Signup</h2>
        <p className="signup-message">
          {" "}
          By signing up, you gain access to exclusive deals, personalized
          coverage options, and a seamless insurance experience. Your security
          and peace of mind are our top priorities.
        </p>
        <form className="signup-form">
          {InputFields.map((input, index) => (
            <Fragment key={index}>
              <div
                style={{
                  margin: "0",
                }}
              >
                <label htmlFor={input.label}>
                  {input.label}:{" "}
                  <span className="required-input">
                    {input.required ? "*" : ""}
                  </span>
                </label>
                <input
                  type={input.type}
                  name={input.input}
                  value={formData[input.input] || ""}
                  onChange={handleChange}
                  maxLength={input.maxLength}
                  pattern={input.pattern}
                  required={input.required}
                  className="signup-input"
                  autoComplete="current-password"
                  placeholder={`Enter your ${input.label}`}
                  id={input.label}
                />
                {formErrors[input.input] && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      paddingLeft: "5px",
                    }}
                  >
                    {formErrors[input.input]}
                  </p>
                )}
              </div>
            </Fragment>
          ))}
        </form>

        <div className="singup-btn-container">
          <button type="button" className="signup-btn" onClick={handleSignup}>
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
