

import "./style.css";

const CustomerSupport = () => {
    return <>
        <div className="customer-support-container ">
            Customer Support
        </div>
    </>
}

export default CustomerSupport;