import { useRef, useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import PopUp from '../../Components/PopUp/index'


const phoneNumber = '9901105641'

const Contactus = () => {
  const Contactinfo = [
    {
      id: 1,
      title: "Whatsapp",
      details: "For any business related queries, WhatsApp to avail us.",
      number: 8050471555,
      imageUrl: (
        <svg xmlns="http://www.w3.org/2000/svg" height="4em" viewBox="0 0 448 512" fill='green'><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
      ),
      link:`https://api.whatsapp.com/send?phone=${phoneNumber}`,
      seemore: "CHAT US"
    
    },
    {
      id: 2,
          title: "Customer care",
          details: "To contact India Insurance Mart Customer Care, use the contact details updated here.Please do not use the contact details.fake and may risk your account security",
          imageUrl: <svg xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 512 512" fill='#0B7CFE'><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>,
          seemore: "CALL BACK US",
          link:"",
          f:openCallBackPopup
    },
    {
      id: 3,
          title: "Write us",
          details: " You can write to us at contactus @indiainsurancemart.com Sri vivekananda vilas,#448 2nd Floor,  2nd Cross Road,IV Block,HBR Layout(Sri Brindavan Nagar) Bangalore - 560 043.",
          imageUrl: <svg xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 512 512" fill='#FE970B'><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>,
          seemore: "WRITE BACK US",
          link: ""
    }
  ];
  
  const form = useRef();
  const Name = useRef(null);
  const EmailId = useRef(null);
  const PhoneNumber = useRef(null);
  const Query = useRef(null);

  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isCallBackPopupOpen, setisCallBackPopupOpen] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  let [data, setData] = useState([]);


  function openCallBackPopup () {
    setisCallBackPopupOpen(!isCallBackPopupOpen);
  }


  useState(() => {
    let fetchData = async () => {
      try {
        let response = await fetch(`http://dev.indiainsurancedeals.com/phpmailer_smtp/test.php`);
        if (response.ok) {
          const datas = await response.json();
          setData(datas);
        } else {
          throw new Error(`Failed to fetch Data: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [data]);

  let addData = async (e) => {
    e.preventDefault();

    setEmailError(''); 
    setPhoneError('');


    if (isFormSubmitted) {
      alert('User data already exists.')
    }

    let info = {
      Name: Name.current.value,
      EmailId: EmailId.current.value,
      PhoneNumber: PhoneNumber.current.value,
      Query: Query.current.value,
    };

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^[0-9]{10}$/;

    let isValid = true;

    if (!info.EmailId || !emailRegex.test(info.EmailId)) {
      setEmailError('* Enter a valid email address.');
      isValid = false;
    }

    if (!info.PhoneNumber || !phoneRegex.test(info.PhoneNumber)) {
      setPhoneError('* Enter a valid phone number.');
      isValid = false;
    }

    if (!info.Name || !info.Query) {
      alert("Please fill out all required fields");
      isValid = false;
    }

    if (isValid) {
      try {
        const response = await fetch(`http://dev.indiainsurancedeals.com/phpmailer_smtp/test.php`, {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          body:  new URLSearchParams(info).toString(),
        });

        if (response.ok) {
          alert("Thank you for submitting");

          Name.current.value = '';
          EmailId.current.value = '';
          PhoneNumber.current.value = '';
          Query.current.value = '';
        } else {
          throw new Error("Failed to submit data");
        }
      } catch (error) {
        console.error(error);
        alert("Failed to submit data. Please try again later");
      }
    }
  };


  return (
    <>
      <div className='contactus-main-container'>
        {isFormSubmitted ? (
          <div className="already-submitted-message">
            <p>User data already exists. You cannot submit the form again.</p>
          </div>
        ) : (
          <div className="forms" ref={form} >
          <div className="sectionform">
            <form action="" className='contactus-form' onSubmit={addData}>
              <h4 className='contactus-heading'>Need Help? Submit details to us.</h4>
              <input ref={Name} type="text" placeholder='Name' className='contactus-input' />
              <input ref={EmailId} type="email" placeholder='Email id' className='contactus-input' /> {emailError && <p className='error'>{emailError}</p>}
              <input ref={PhoneNumber} type="tel" name="" id="" placeholder='Phone number' className='contactus-input' /> {phoneError && <p className='error'>{phoneError}</p>}
              <label htmlFor="query" className='contactus-heading'>Write queries to us.</label>
              <textarea ref={Query} name="write queries to us" id="query" cols="30" rows="10"></textarea>
              <button className='submit-btn'>Submit</button>
            </form>
          </div>

          <div className="contactus-container">
            {Contactinfo.map((info) => (
              <div className="card" key={info.id}>
                <div className="content-box">
                  <span className="card-title">{info.title}</span>
                  <p className="card-content">{info.details}</p>
                  <Link className="see-more" to={info.link} onClick={info.f}>{info.seemore}</Link>
               <PopUp isOpen={isCallBackPopupOpen} onClose={openCallBackPopup}/>
                </div>
                <div className="date-box">
                  {info.imageUrl}
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
      </div>
    </>
  );
};

export default Contactus;
