import { memo, useState, useContext } from "react";
import Cookies from "js-cookie";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { planData } from "../../Components/ContextApi";
const LoginComponent = () => {
  const navigate = useNavigate();
  const { setUserCredentials, setCredentials, setUserId } =
    useContext(planData);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const userNameHandler = ({ target: { value } }) => {
    setEmail(value);
  };
  const passwordHandler = ({ target: { value } }) => {
    setPassword(value);
  };

  const validateForm = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    }

    return isValid;
  };
  const handleSignIn = async () => {
    const userDetails = {
      email_id: email,
      password: password,
      tbname: "customer",
    };
    setEmailError("");
    setPasswordError("");
    if (!validateForm()) {
      return;
    } else {
      const apiUrl = "https://api.indiainsurancedeals.com/v1/common/login.php";
      axios
        .post(apiUrl, JSON.stringify(userDetails))
        .then((response) => {
          if (response.data.status_code === "100") {
            alert("Logged in Successfully");
            const expirationInMinutes = 20;
            const expirationInDays = expirationInMinutes / (24 * 60);
            Cookies.set("userData", JSON.stringify(response.data), {
              expires: expirationInDays,
            });
            setUserCredentials(response.data);
            setCredentials(false);
            setUserId(response.data.id);
            navigate("/");
          } else if (response.data.status_code === "101") {
            alert("Enter Correct Password");
          } else if (response.data.status_code === "102") {
            alert("Login failed. User not found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="login-main-container">
      <form className="login-form">
        <h3 className="login-heading">Login</h3>
        <label htmlFor="email">Email: </label>
        <input
          type="email"
          onChange={userNameHandler}
          required
          autoComplete="current-password"
          className="login-input"
          placeholder="Enter registered Email"
          id="email"
        />
        {emailError && <p className="error-message">{emailError}</p>}

        <label htmlFor="password">Password: </label>
        <input
          type={showPassword ? "text" : "password"}
          onChange={passwordHandler}
          required
          autoComplete="current-password"
          id="password"
          className="login-input"
          placeholder="Enter your password"
        />
        {passwordError && <p className="error-message">{passwordError}</p>}

        <div className="show-password">
          <input
            type="checkbox"
            onClick={togglePasswordVisibility}
            id="checkbox-input"
          />
          <span className="password-hint"> Show Password</span>
        </div>
        <div className="sign-in-Reset">
          <Link to="/Reset">Reset Password</Link>
        </div>
        <button type="button" className="login-in-btn" onClick={handleSignIn}>
          Login
        </button>
        <div className="clickUser">
          <span>
            New User? <Link to="/signUp"> Sign Up</Link>
          </span>
        </div>
      </form>
    </div>
  );
};

export default memo(LoginComponent);
