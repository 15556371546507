import React, { useContext, useState, Fragment } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { planData } from "../ContextApi";
import { useNavigate } from "react-router-dom";

const CustomNavdropdown = ({ title, insurancePlans, i_type, handler }) => {
  const navigateto = useNavigate();
  const { getPathData } = useContext(planData);

  const [planName, setPlanName] = useState(title);

  const NavigationHandler = (plan) => {
    getPathData(plan.id);
    setPlanName(plan.plans);
    navigateto(`/products-${plan.id}`);
  };

  const healthNavigation = (plan2) => {
    getPathData(plan2.id);
    setPlanName(plan2.plans);
    navigateto(`/healthproducts-${plan2.id}`);
  };

  const generalNavigation = (plan3) => {
    getPathData(plan3.id);
    setPlanName(plan3.plans);
    navigateto(`/generalproducts-${plan3.id}`);
  };

  const InsuranceTypeHandler = (plan) => {
    handler();
    if (i_type === "life") {
      NavigationHandler(plan);
    } else if (i_type === "health") {
      healthNavigation(plan);
    } else {
      generalNavigation(plan);
    }
  };

  return (
    <>
      <NavDropdown title={planName} variant="danger" id="dropdown-basic">
        {insurancePlans.map((plan, index) => {
          return (
            <Fragment key={index}>
              <NavDropdown.Item
                onClick={() => {
                  InsuranceTypeHandler(plan);
                }}
                value={plan.id}
              >
                {plan.plans}
              </NavDropdown.Item>
            </Fragment>
          );
        })}
      </NavDropdown>
    </>
  );
};

export default CustomNavdropdown;
