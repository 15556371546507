import React from "react";
import  Modal  from "react-modal";
import './style.css'
Modal.setAppElement('#root');

const PopUp = ({ isOpen, onClose}) => {
    return <>        
        <Modal 
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Call Back Us Popup"
        >
            <section>
                <div className="Block">
                    <h5>Call Back Us</h5>
                    <div className="Details">
                        <input type="text" placeholder="Enter Name"></input>
                        <input type="tel" placeholder="Enter PhoneNumber"></input>
                    </div>
                    <span>
                    <button onClick={onClose}>Close</button>
                    <button type="submit">Submit</button>
                    </span>
                </div>
            </section>
        </Modal>
     </>
}
 
export default PopUp;