import React, { useState, useEffect } from "react";
import axios from "axios";

const WorkwithApi = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // const params = {
      //   tbname: "customer",
      //   propname: "customer",
      //   name: "XYZ1",
      //   email_id: "lokesh@gmail.com",
      //   password: "loki",
      //   home_number: "9897654454",
      //   office_number: "9876655455",
      //   mobile_number: "6577764666",
      //   house_no: "1-45",
      //   address_line_1: "Chowdireddypalli",
      //   address_line_2: "Gangavaram",
      //   state: "andhrapradesh",
      //   city: "palamaner",
      //   country: "India",
      //   pin_code: "sdasa",
      //   is_email_verified: true,
      //   is_mobile_verified: true,
      // };
      const xyz = {
        tbname: "customer",
        propname: "customer",
        id: 10,
      };
      try {
        const response = await axios.post(
          "http://api.indiainsurancedeals.com/v1/common/read.php",
          xyz
        );
        setData(response.data);

        if (response) {
          alert("Success");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Data from PHP Server</h2>
      {data ? <span>{JSON.stringify(data, null, 2)}</span> : <p>Loading...</p>}
    </div>
  );
};

export default WorkwithApi;
