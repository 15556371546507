import React, { useContext, useState, useEffect, Fragment } from "react";
import Highlights from "../Highlights";
import FooterComponent from "../Footer/index";
import { planData } from "../ContextApi";
// import { useNavigate } from "react-router-dom";
import general from "../../Data/CSVData/Generalcsv/general.json";
import "./style.css";
//general Company Images

import zuno from "../../Assets/general/zuno.png";
import rayal from "../../Assets/general/rayal.jpeg";
import national from "../../Assets/general/national.webp";
import gdi from "../../Assets/general/gdi.jpg";
import kotak from "../../Assets/general/kgi.png";
import magma from "../../Assets/general/magma.png";
import sbi from "../../Assets/general/sbig.png";
import hdfc from "../../Assets/general/hdfc.jpg";
import reliance from "../../Assets/general/religi.png";
import bajajgi from "../../Assets/general/bajajgi.jpg";
import tniagi from "../../Assets/general/tniai.jpg";
import uigi from "../../Assets/general/uigi.png";
import cmgi from "../../Assets/general/cmgi.png";
import iffco from "../../Assets/general/iffco.jpeg";
import shriram from "../../Assets/general/shriram.png";
import raheja from "../../Assets/general/raheja.webp";
import liberty from "../../Assets/general/liberty.jpg";
import icic from "../../Assets/general/icicgi.jpeg";
import bagi from "../../Assets/general/bagi.png";
import ogi from "../../Assets/general/ogi.png";
import emailjs from "emailjs-com";
const companies = [
  { company_Image: zuno, companyname: "Zuno General insurance", id: 1 },
  {
    company_Image: rayal,
    companyname: "Rayal Sudharam General insurance",
    id: 2,
  },
  {
    company_Image: national,
    companyname: "National insurance General insurance",
    id: 3,
  },
  { company_Image: gdi, companyname: "GO Digit General insurance", id: 4 },
  { company_Image: kotak, companyname: "kotak General insurance", id: 5 },
  { company_Image: magma, companyname: "Magma HDI General insurance", id: 6 },
  { company_Image: sbi, companyname: "SBI General  insurance", id: 7 },
  { company_Image: hdfc, companyname: "HDFC ERGO General insurance", id: 8 },
  { company_Image: reliance, companyname: "Reliance General insurance", id: 9 },
  {
    company_Image: bajajgi,
    companyname: "BAJAJ Allianz General insurance",
    id: 10,
  },
  {
    company_Image: tniagi,
    companyname: "The new india Assurance General insurance",
    id: 11,
  },
  {
    company_Image: uigi,
    companyname: "United india General Insurance",
    id: 12,
  },
  {
    company_Image: ogi,
    companyname: "Oriental General insurance",
    id: 13,
  },
  {
    company_Image: cmgi,
    companyname: "Chola MS General insurance ",
    id: 14,
  },
  { company_Image: iffco, companyname: "IFFCO-TOKIO", id: 15 },
  { company_Image: shriram, companyname: "Shriram General insurance", id: 16 },
  {
    company_Image: raheja,
    companyname: "Raheja QBE General insurance",
    id: 17,
  },
  { company_Image: liberty, companyname: "Liberty General insurance", id: 18 },
  {
    company_Image: icic,
    companyname: "Icici Lombard General insurance",
    id: 19,
  },
  {
    company_Image: bagi,
    companyname: "Bharti axa General insurance",
    id: 20,
  },
];
const Generalproducts = () => {
  const [generalProduct, setGeneralProduct] = useState({});
  const { planDetails, planId } = useContext(planData);
  const [companyName, setCompanyname] = useState("Zuno General insurance ");
  const [inputData, setInputData] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const [renderForm, setRenderForm] = useState(true);
  const [planImages, setPlanImages] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(
    "Zuno General insurance "
  );

  useEffect(() => {
    setGeneralProduct(
      general.find((g) => {
        return g.Id === planId;
      })
    );
    setPlanImages(generalProduct.Companies);
  }, [planId, planImages, generalProduct.Companies]);

  const inputChangeHandler = (inputName, value) => {
    setInputData((prevData) => ({
      ...prevData,
      selectedProduct,
      [inputName]: value,
    }));
  };

  const dataToSend = { selectedProduct, ...inputData };

  const submitHandler = async () => {
    const emailParams = {
      to_email: "recipient@example.com",

      from_name: inputData["Owner Full Name"],
      subject: "New General Product Inquiry",
      message: `Product: ${dataToSend}\n\n${JSON.stringify(
        inputData,
        null,
        2
      )}`,
    };

    try {
      await emailjs.send(
        "service_io1643p",
        "template_ocpv7ly",
        emailParams,
        "_uBEmCU31tn-upplB"
      );
      setRenderForm(false);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <>
      <div className="product-main-container">
        <div className="Product-details-container">
          <div className="product-highlights-container">
            <div className="product-details">
              <span className="product-type">{planDetails.Product_Name}</span>
              <h5 className="product-headings">Description</h5>
              <p className="details-description">{planDetails.description}</p>
              <h5 className="product-headings">Benefits</h5>
              <p className="details-description">{planDetails.benefits}</p>
            </div>
            <div>
              <Highlights />
            </div>
          </div>
          <div className="company">
            <div className="company-container">
              {companies.map((company, i) => {
                return (
                  <Fragment key={i}>
                    <img
                      src={company.company_Image}
                      alt=""
                      className="company-image"
                      onClick={() => {
                        setCompanyname(company.companyname);
                        setRenderForm(true);
                        setSelectedProduct(company.companyname);
                      }}
                    />
                  </Fragment>
                );
              })}
            </div>
            <div></div>
          </div>
          <div className="table-container">
            {renderForm ? (
              <div>
                <label htmlFor="product" className="general-input">
                  Product Name
                </label>
                <input
                  type="text"
                  id="product"
                  value={companyName}
                  onChange={(e) => setCompanyname(e.target.value)}
                />

                {generalProduct.Input_feilds &&
                  generalProduct.Input_feilds.map((input, index) => (
                    <Fragment key={index}>
                      <div className="input-container">
                        <label htmlFor={input.id}>
                          {input}
                          <input
                            id={input.id}
                            name={input.companyName}
                            type="text"
                            autoComplete="current-password"
                            className="general-input"
                            placeholder={input}
                            onChange={(e) => {
                              inputChangeHandler(input, e.target.value);
                              setInputErrors((prevErrors) => ({
                                ...prevErrors,
                                [input]: "",
                              }));
                            }}
                          />
                        </label>
                        {inputErrors[input] && (
                          <div className="error-message">
                            {inputErrors[input]}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  ))}
                <button className="sub-button" onClick={submitHandler}>
                  Submit
                </button>
              </div>
            ) : (
              <div className="response-container">
                <p>
                  Thanks For Your Time we got your Data we will get back to you
                  with in 24 hours
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <FooterComponent />
    </>
  );
};

export default Generalproducts;
