import { memo, useState, useEffect, Fragment, useContext } from "react";
import "./style.css";
import logo from "../../Assets/iid-logo.png";
import { Link } from "react-router-dom";
import CallbackSlider from "../CallbackSlider";
import Chatbot from "../ChatBot";
import "../ChatBot/style.css";
import chatbot from "../../Assets/chatbot.gif";
import profile from "../../Assets/profile.png";
import { planData } from "../ContextApi";
import CustomNavdropdown from "../NavDropdown";
const sections = [
  { id: "home", label: "Home", url: "/" },
  { id: "about", label: "About", url: "/about" },
  { id: "contact", label: "Contact", url: "/contact" },
];

const LifeInsurances = [
  { id: "TROP", plans: "Term Insurance With Return of Premium" },
  { id: "TRO", plans: "Term Insurance Without Return of Premium" },
  { id: "CEP", plans: "Child Eductaion Plan" },
  { id: "CMP", plans: "Child Marriage Plan" },
  { id: "LLIP", plans: "Life Long Income Plan" },
  { id: "LP", plans: "Lumpsum Plan" },
  { id: "PP", plans: "Pension Plan" },
  { id: "MBP", plans: "Money Back Plan" },
  { id: "MRIP", plans: "Monthly Regular Income Plan" },
];

const HealthInsurances = [
  { id: "1_HSTD", plans: "Standard Health Insurance" },
  { id: "2_HSSTD", plans: "Sub Standard Health Insurance" },
  { id: "3_HT", plans: "Travel Health Insurance" },
  { id: "4_HPA", plans: "Personal Accident Health Insurance" },
  { id: "5_HN", plans: "National Health Insurance" },
  { id: "6_HG", plans: "Global Health Insurance" },
  { id: "7_HM", plans: "Maternity Health Insurance" },
  { id: "8_HWP", plans: "Womens plus Health Insurance" },
  { id: "9_HAHC", plans: "Additional Health Coverage" },
  { id: "10_HP", plans: "Health Premier" },
];

const GeneralInsurance = [
  { id: "CARI", plans: "Car Insurance" },
  { id: "BIKEI", plans: "2-Wheeler Insurance" },
  { id: "HOMEI", plans: "Home Insurance" },
  { id: "PETI", plans: "Pet Insurance" },
];
// const phoneNumber = "7619484492";

const registrations = [
  { id: "singup", label: "Sing-Up", url: "/signup" },
  { id: "login", label: "Login ", url: "/login" },
];
const profilebtn = [{ id: "profile", label: "Profile ", url: "/profile" }];
const NavComponent = () => {
  const [menu, setMenu] = useState(true);
  const { credentials, userId, userCredentials } = useContext(planData);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chatbotVisible, setChatbotVisible] = useState(false);
  const phoneNumber = "+918050471555";
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [credentials, userCredentials, menu]);
  const menuHandler = () => {
    setMenu(!menu);
  };
  const toggleChatbot = () => {
    setChatbotVisible(!chatbotVisible);
  };
  return (
    <>
      <div className="nav-container">
        <div className="logo-container">
          <img src={logo} alt="logo" className="logo-img" />
        </div>
        <div className="links-container">
          <div className="plan-links"></div>
          {windowWidth >= 925 ? (
            <div className="links linksbutton-container">
              <CustomNavdropdown
                title="Life Insurance"
                insurancePlans={LifeInsurances}
                i_type="life"
                handler={menuHandler}
              />
              <CustomNavdropdown
                title="Health Insurance"
                insurancePlans={HealthInsurances}
                i_type="health"
                handler={menuHandler}
              />
              <CustomNavdropdown
                title="General Insurance"
                insurancePlans={GeneralInsurance}
                i_type="general"
                handler={menuHandler}
              />
              {sections.map((link, index) => {
                return (
                  <Fragment key={index}>
                    <Link to={link.url} className="links" id={link.id}>
                      {link.label}
                    </Link>
                  </Fragment>
                );
              })}

              <div className="profile">
                {credentials ? (
                  <>
                    {registrations.map((link, index) => {
                      return (
                        <Fragment key={index}>
                          <Link to={link.url} className="links" id={link.id}>
                            {link.label}
                          </Link>
                        </Fragment>
                      );
                    })}
                  </>
                ) : (
                  <Link to={`/profile-${userId}`}>
                    <img
                      src={profile}
                      alt="profile"
                      className="profile-image"
                    />
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <div className="links menu-container">
              <div onClick={menuHandler}>
                {windowWidth < 925 && menu ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.5em"
                    viewBox="0 0 448 512"
                    fill="blueviolet"
                  >
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.5em"
                    viewBox="0 0 384 512"
                    fill="blueviolet"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                )}
              </div>
              {!menu ? (
                <div className="menu-buttons">
                  <CustomNavdropdown
                    title="Life Insurance"
                    insurancePlans={LifeInsurances}
                    i_type="life"
                    handler={menuHandler}
                  />
                  <CustomNavdropdown
                    title="Health Insurance"
                    insurancePlans={HealthInsurances}
                    i_type="health"
                    handler={menuHandler}
                  />
                  <CustomNavdropdown
                    title="General Insurance"
                    insurancePlans={GeneralInsurance}
                    i_type="general"
                    handler={menuHandler}
                  />
                  {credentials ? (
                    <>
                      {[...sections, ...registrations].map((link, index) => {
                        return (
                          <Fragment key={index}>
                            <Link
                              to={link.url}
                              className="links"
                              onClick={menuHandler}
                            >
                              {link.label}
                            </Link>
                          </Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {[...sections].map((link, index) => {
                        return (
                          <Fragment key={index}>
                            <Link
                              to={link.url}
                              className="links"
                              onClick={menuHandler}
                            >
                              {link.label}
                            </Link>
                          </Fragment>
                        );
                      })}
                      {profilebtn.map((link, index) => {
                        return (
                          <Fragment key={index}>
                            <Link
                              to={`/profile-${userId}`}
                              className="links"
                              onClick={menuHandler}
                            >
                              {link.label}
                            </Link>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className="callback-btn">
        <CallbackSlider />
      </div>

      <div className="whatsapp-icon">
        <Link to={`https://wa.me/${phoneNumber}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2.5em"
            viewBox="0 0 448 512"
            fill="green"
            className="whatsapp-logo"
          >
            <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
          </svg>
        </Link>
      </div>

      <div className="chatbot" onClick={toggleChatbot}>
        <img src={chatbot} alt="chatbot" className="chatbot-gif" />
      </div>
      {chatbotVisible ? (
        <div className="chatbot-icon">
          <Chatbot />
        </div>
      ) : null}
    </>
  );
};

export default memo(NavComponent);
