import React, { createContext, useState, useEffect, memo } from "react";
import Papa from "papaparse";
import Cookies from "js-cookie";
// Life Insurances
import TROP from "../../Data/CSVData/Lifecsv/TROP.csv";
import TRO from "../../Data/CSVData/Lifecsv/TRO.csv";
import CEP from "../../Data/CSVData/Lifecsv/CEP.csv";
import CMP from "../../Data/CSVData/Lifecsv/CMP.csv";
import LLIP from "../../Data/CSVData/Lifecsv/LLIP.csv";
import LP from "../../Data/CSVData/Lifecsv/LP.csv";
import MBP from "../../Data/CSVData/Lifecsv/MBP.csv";
import MRIP from "../../Data/CSVData/Lifecsv/MRIP.csv";
import PP from "../../Data/CSVData/Lifecsv/PP.csv";
import plans from "../../Data/planDetails.json";
import Alldetails from "../../Data/Allroductdetails.json";

//Heailth Insurances

import health from "../../Data/CSVData/Healthcsv/healthproducts.csv";

export const planData = createContext();
const healthProducts = [
  { id: "TROP", path: TROP },
  { id: "TRO", path: TRO },
  { id: "CEP", path: CEP },
  { id: "CMP", path: CMP },
  { id: "LLIP", path: LLIP },
  { id: "LP", path: LP },
  { id: "MBP", path: MBP },
  { id: "MRIP", path: MRIP },
  { id: "PP", path: PP },
  { id: "1_HSTD", path: health },
  { id: "2_HSSTD", path: health },
  { id: "3_HT", path: health },
  { id: "4_HPA", path: health },
  { id: "5_HN", path: health },
  { id: "6_HG", path: health },
  { id: "7_HM", path: health },
  { id: "8_HWP", path: health },
  { id: "9_HAHC", path: health },
  { id: "10_HP", path: health },
  { id: "CARI", path: "Car Insurance" },
  { id: "BIKEI", path: "2-Wheeler Insurance" },
  { id: "HOMEI", path: "Home Insurance" },
  { id: "PETI", path: "Pet Insurance" },
];

const ContextApi = ({ children }) => {
  const [filePath, setFilepath] = useState(TROP);
  const [csvData, setCsvData] = useState([]);
  const [planDetails, setPlandetails] = useState();
  const [product, setProduct] = useState();
  const [companyLogo, setCompanyLogo] = useState(
    "/static/media/bhartiaxa.fc1ab858c9e4414abf28.png"
  );
  const [planId, setplanId] = useState();
  const [productId, setProductId] = useState();
  const [credentials, setCredentials] = useState(true);
  const [userCredentials, setUserCredentials] = useState({});
  const [userId, setUserId] = useState(null);
  const getPathData = (value) => {
    setPlandetails(plans.find((plan) => plan.id === value));

    const healthpath = healthProducts.find(
      (health_path) => health_path.id === value
    );
    if (healthpath && healthpath.path) {
      setFilepath(healthpath.path);
    }
  };
  useEffect(() => {
    const currentURL = window.location.href;
    const value = currentURL.split("-")[1];

    setplanId(value);
    setPlandetails(plans.find((plan) => plan.id === value));
    const foundProduct = Alldetails.find((p) => p.id === Number(value));
    setProduct(
      foundProduct
        ? foundProduct
        : {
            id: 302,
            ProductName: "Product Name",
            Benefits: Array(2),
            Highlights: "Data is not available",
            productdescription: "Data is not available",
          }
    );

    const healthpath = healthProducts.find(
      (health_path) => health_path.id === value
    );
    if (healthpath && healthpath.path) {
      setFilepath(healthpath.path);
    }

    if (filePath) {
      fetch(filePath)
        .then((response) => response.text())
        .then((data) => {
          Papa.parse(data, {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
              setCsvData(result.data);
            },
          });
        })
        .catch((error) => console.error(error));
    }
    const userData = Cookies.get("userData");
    if (userData) {
      setCredentials(userData);
      const jsonObject = JSON.parse(userData);
      const idValue = jsonObject.id;
      setUserId(idValue);
      setCredentials(false);
    } else {
      setCredentials(true);
    }
  }, [filePath, csvData]);
  // console.log("runned");
  const productDetailHandler = (id, image) => {
    setCompanyLogo(image);
    const foundProduct = Alldetails.find((p) => p.id === id);
    setProduct(
      foundProduct
        ? foundProduct
        : {
            id: 302,
            ProductName: "Product Name",
            Benefits: Array(2),
            Highlights: "Data is not available",
            productdescription: "Data is not available",
          }
    );
  };

  return (
    <>
      <planData.Provider
        value={{
          planDetails,
          filePath,
          csvData,
          getPathData,
          product,
          productDetailHandler,
          companyLogo,
          planId,
          productId,
          credentials,
          setCredentials,
          userCredentials,
          setUserCredentials,
          userId,
          setUserId,
          setProductId,
        }}
      >
        {children}
      </planData.Provider>
    </>
  );
};

export default memo(ContextApi);
