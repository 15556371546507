import { memo } from "react";
import "./style.css";

const Introduction = () => {
  return (
    <>
      <div className="introduction-container">
        <div className="intro">
          <h3 className="intro-heading">India Inusrance Deals</h3>
          <p>
            {" "}
            India Insurance Deals, your trusted insurance partner, is a leading
            insurance company in India. With a rich legacy of providing reliable
            insurance solutions, we stand as a beacon of security for
            individuals and businesses alike. Our comprehensive range of
            insurance products spans health, property, vehicles, businesses, and
            more, ensuring that you have the right coverage for every aspect of
            your life. What sets us apart is our unwavering commitment to you,
            our valued customer. We prioritize your unique insurance
            needs.professionals is at your service.
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(Introduction);
