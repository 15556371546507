import { Fragment, memo } from "react";
import "./style.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
const Highlights = () => {
  const pageHighlights = [
    {
      id: 1,
      imageUrl:
        "https://www.eurokidsindia.com/blog/wp-content/uploads/2023/01/importance-of-early-year-child-education.jpg",
      heading: "High-light-1",
    },
    {
      id: 2,
      imageUrl:
        "https://www.wintwealth.com/blog/wp-content/uploads/2022/10/Features-Benefits-of-Pension-Plans-in-India.jpg",
      heading: "High-light-2",
    },
    {
      id: 3,
      imageUrl:
        "https://www.angelone.in/wp-content/uploads/2021/06/Lump-Sum-Investment-or-A-Systematic-Investment-Plan-SIP.jpg",
      heading: "High-light-3",
    },
    {
      id: 4,
      imageUrl:
        "https://life.futuregenerali.in/media/t2hmk32d/what-are-the-advantages-of-money-back-policy.webp",
      heading: "High-light-4",
    },
    {
      id: 5,
      imageUrl:
        "https://b4investing.com/wp-content/uploads/2021/05/monthly-income-plan-b4inves.jpg",
      heading: "High-light-5",
    },
  ];
  return (
    <>
      <div className="highlights-container">
        <MDBCarousel
          showControls
          showIndicators
          dark
          scroll="true"
          fade
          className="mdbCarousel"
        >
          {pageHighlights.map((highlight) => {
            const { imageUrl, id, heading } = highlight;
            return (
              <Fragment key={id}>
                <MDBCarouselItem
                  className="w-80 d-block"
                  itemId={id}
                  src={imageUrl}
                  alt="..."
                >
                  {" "}
                  <h5>{heading}</h5>
                </MDBCarouselItem>
              </Fragment>
            );
          })}
        </MDBCarousel>

        {/* </div> */}
      </div>
    </>
  );
};

export default memo(Highlights);
