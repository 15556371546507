import { useContext, memo, Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { planData } from "../ContextApi";
import Highlights from "../Highlights";
import FooterComponent from "../Footer/index";
import PdfDownloads from "../PdfDownloads/index";
// import FilterComponent from "../Filters";
import "./style.css";
import CustomModal from "../Modal";
import axios from "axios";

const Test = () => {
  const [products, setProducts] = useState([]);
  let [benefits, setBenefits] = useState([]);
  const navigateto = useNavigate();
  const { csvData, planDetails, productDetailHandler, setProductId } =
    useContext(planData);
  const naviagteToproduct = (id, companylogo) => {
    productDetailHandler(id, companylogo);
    setProductId(id);
    navigateto(`/test2/${id}`);
  };
  useEffect(() => {
    async function datafetching() {
      const url =
        "https://api.indiainsurancedeals.com/v1/common/productTypeReadById.php";
      const params = {
        product_type_id: "22",
      };
      try {
        const response = await axios.post(url, JSON.stringify(params), {
          headers: {
            "Content-Type": "applictaion/json",
          },
        });
        if (response) {
          setProducts(response.data.data);

          setBenefits(response.data.data.product_type.benefits.split(","));
        }
      } catch (error) {
        console.log(error);
      }
    }
    datafetching();
  }, []);

  return (
    <>
      <div className="product-main-container">
        <div className="Product-details-container">
          <div className="product-highlights-container">
            <div className="product-details">
              <span className="product-type">
                {products?.product_type?.Product_Name}
              </span>
              <h5 className="product-headings">Description</h5>
              <p className="details-description">
                {products?.product_type?.description}
              </p>
              <h5 className="product-headings">Benefits</h5>
              <ul className="details-description">
                {benefits?.map((b) => {
                  return (
                    <>
                      <li>{b}</li>
                    </>
                  );
                })}
              </ul>
            </div>
            <div>{<Highlights />}</div>
          </div>
          {/* <div className="filter-container">{<FilterComponent />}</div> */}
          <div className="table-container">
            {products?.products?.map((p, index) => {
              return (
                <Fragment key={index}>
                  <div className="products-card">
                    <div>
                      <img
                        src={p?.Image_Id}
                        alt="comapany-logo"
                        className="company-logo-image"
                      ></img>
                    </div>
                    <div className="productname">
                      <h6>Product Name</h6>
                      <span>{p?.product_name}</span>
                    </div>
                    <div>
                      <h6>Min Age - Max Age</h6>
                      <span>
                        {p?.min_age} - {p?.max_age}
                      </span>
                    </div>
                    <div>
                      <h6>Policy Term</h6>
                      <span>{p?.Policy_Term}</span>
                    </div>
                    <div>
                      <h6>Sum Assured</h6>
                      <span>{p?.sum_assured}</span>
                    </div>
                    <div>
                      <h6>Claim status</h6>
                      <span>{p?.claim_status}</span>
                    </div>

                    <div>
                      <h6>Riders</h6>
                      <span>{p?.rider}</span>
                    </div>
                    <div>
                      <h6>Premium Paying Term</h6>
                      <span>{p?.Premium_paying_Term}</span>
                    </div>
                    {/* <div>
                      <h6>{planDetails.returns}</h6>
                      <span>15,00000 - at 4%</span>
                      <span>15,00000 - at 8%</span>
                    </div> */}
                    <div>
                      <PdfDownloads id={p?.brochure} />
                    </div>
                    <div>
                      <button
                        className="knowmore"
                        onClick={() => naviagteToproduct(p?.id, p?.Image_Id)}
                      >
                        Know More{" "}
                        <i className="fa-solid fa-angle-up fa-rotate-90"></i>
                      </button>
                    </div>
                    <div className="buy-btn">
                      <CustomModal productname={p?.product_name} />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      <FooterComponent />
    </>
  );
};

export default memo(Test);
