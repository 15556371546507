import "./style.css";
import CustomDropdown from "../Dropdown";
import CompanyBased from "../CompanyBased";
import kli from "../../Assets/kotak.png";
import ba from "../../Assets/bajaj-allianz.jpeg";
import bali from "../../Assets/bhartiaxa.png";
import hdfc from "../../Assets/hdfc.png";
import lic from "../../Assets/lic.webp";
import mli from "../../Assets/maxlifeinsurance.png";
import sbi from "../../Assets/sbi.webp";
import pnb from "../../Assets/pnbmetlife.png";
import ab from "../../Assets/Aditya_Birla.png";
import { Fragment, useState } from "react";

const LifeInsurances = [
  { id: "TROP", plans: "Term Insurance With Return of Premium", type: "life" },
  {
    id: "TRO",
    plans: "Term Insurance Without Return of Premium",
    type: "life",
  },
  { id: "CEP", plans: "Child Eductaion Plan", type: "life" },
  { id: "CMP", plans: "Child Marriage Plan", type: "life" },
  { id: "LLIP", plans: "Life Long Income Plan", type: "life" },
  { id: "LP", plans: "Lumpsum Plan", type: "life" },
  { id: "PP", plans: "Pension Plan", type: "life" },
  { id: "MBP", plans: "Money Back Plan", type: "life" },
  { id: "MRIP", plans: "Monthly Regular Income Plan", type: "life" },
];
const HealthInsurances = [
  { id: "1_HSTD", plans: "Standard Health Insurance", type: "health" },
  { id: "2_HSSTD", plans: "Sub Standard Health Insurance", type: "health" },
  { id: "3_HT", plans: "Travel Health Insurance", type: "health" },
  { id: "4_HPA", plans: "Personal Accident Health Insurance", type: "health" },
  { id: "5_HN", plans: "National Health Insurance", type: "health" },
  { id: "6_HG", plans: "Global Health Insurance", type: "health" },
  { id: "7_HM", plans: "Maternity Health Insurance", type: "health" },
  { id: "8_HWP", plans: "Womens plus Health Insurance", type: "health" },
  { id: "9_HAHC", plans: "Additional Health Coverage", type: "health" },
  { id: "10_HP", plans: "Health Premier", type: "health" },
];
const GeneralInsurance = [
  { id: "CARI", plans: "Car Insurance", type: "general" },
  { id: "BIKEI", plans: "2-Wheeler Insurance", type: "general" },
  { id: "HOMEI", plans: "Home Insurance", type: "general" },
  { id: "PETI", plans: "Pet Insurance", type: "general" },
];
const companies = [
  { company_Image: bali, companyname: "Bharati Axa", id: 1 },
  { company_Image: hdfc, companyname: "HDFC", id: 2 },
  { company_Image: ba, companyname: "Bajaj Allianz", id: 3 },
  { company_Image: kli, companyname: "Kotak Mahindra", id: 4 },
  { company_Image: mli, companyname: "Max Life", id: 5 },
  { company_Image: pnb, companyname: "PNB Metlife", id: 6 },
  { company_Image: sbi, companyname: "SBI", id: 7 },
  { company_Image: lic, companyname: "LIC", id: 8 },
  { company_Image: ab, companyname: "Aditya Birla", id: 9 },
];
const AllProducts = () => {
  const [company, setCompany] = useState(1);
  return (
    <>
      <div className="allproducts-main-container">
        <h4 className="all-products-heading1 headings">Insurance Types</h4>
        <div className="dropdowns">
          <CustomDropdown
            insuranceplans={LifeInsurances}
            plantype={"Life Insurance"}
          />
          <CustomDropdown
            insuranceplans={HealthInsurances}
            plantype={"Health Insurance"}
          />
          <CustomDropdown
            insuranceplans={GeneralInsurance}
            plantype={"General Insurance"}
          />
        </div>
        <div className="company-products">
          <h4 className="all-products-heading2 headings">Company Policies</h4>
          <div className="company">
            <div className="company-container">
              {companies.map((company, i) => {
                return (
                  <Fragment key={i}>
                    <img
                      src={company.company_Image}
                      alt=""
                      className="company-image"
                      onClick={() => {
                        setCompany(company);
                      }}
                    />
                  </Fragment>
                );
              })}
            </div>
            <div>
              <CompanyBased c_id={company} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
